// Trivy Secret Categories and Severities
export const SECRET_CATEGORIES = Object.freeze({
  '': 'All',
  AWS: 'AWS',
  GitHub: 'GitHub',
  GitLab: 'GitLab',
  AsymmetricPrivateKey: 'AsymmetricPrivateKey',
  Shopify: 'Shopify',
  Slack: 'Slack',
  Google: 'Google',
  Stripe: 'Stripe',
  PyPI: 'PyPI',
  Heroku: 'Heroku',
  Twilio: 'Twilio',
  Age: 'Age',
  Facebook: 'Facebook',
  Twitter: 'Twitter',
  Adobe: 'Adobe',
  Alibaba: 'Alibaba',
  Asana: 'Asana',
  Atlassian: 'Atlassian',
  Bitbucket: 'Bitbucket',
  Beamer: 'Beamer',
  Clojars: 'Clojars',
  ContentfulDelivery: 'ContentfulDelivery',
  Databricks: 'Databricks',
  Discord: 'Discord',
  Doppler: 'Doppler',
  Dropbox: 'Dropbox',
  Duffel: 'Duffel',
  Dynatrace: 'Dynatrace',
  Easypost: 'Easypost',
  Fastly: 'Fastly',
  Finicity: 'Finicity',
  Flutterwave: 'Flutterwave',
  Frameio: 'Frameio',
  GoCardless: 'GoCardless',
  Grafana: 'Grafana',
  HashiCorp: 'HashiCorp',
  HubSpot: 'HubSpot',
  Intercom: 'Intercom',
  Ionic: 'Ionic',
  Linear: 'Linear',
  Lob: 'Lob',
  Mailchimp: 'Mailchimp',
  Mailgun: 'Mailgun',
  Mapbox: 'Mapbox',
  MessageBird: 'MessageBird',
  NewRelic: 'NewRelic',
  Npm: 'Npm',
  Planetscale: 'Planetscale',
  Postman: 'Postman',
  Pulumi: 'Pulumi',
  RubyGems: 'RubyGems',
  SendGrid: 'SendGrid',
  Sendinblue: 'Sendinblue',
  Shippo: 'Shippo',
  LinkedIn: 'LinkedIn',
  Twitch: 'Twitch',
  Typeform: 'Typeform',
});

export const SECRET_SEVERITIES = Object.freeze({
  '': 'All',
  CRITICAL: 'Critical',
  HIGH: 'High',
  MEDIUM: 'Medium',
  LOW: 'Low',
  UNKNOWN: 'Unknown',
});
