import { useMutation } from '@apollo/client';
import { css } from '@emotion/css';
import { AppEvents, GrafanaTheme2, SelectableValue } from '@grafana/data';
import { getAppEvents } from '@grafana/runtime';
import { AsyncSelect, Button, Modal, RadioButtonList, useStyles2, Field } from '@grafana/ui';
import { AddUserMutation, Role } from '__generated__/graphql';
import { getUsers } from 'api/getUsers';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';

import { ADD_USER } from './AccessControlMutations';
import { GET_USERS } from './AccessControlQueries';
import { UserDTO } from './types';

const loadUserOptions = async (query: string): Promise<Array<SelectableValue<string>>> => {
  const { users } = await getUsers(query);
  return users.map((user: UserDTO) => ({
    label: user.login,
    value: user.login,
    imgUrl: user.avatarUrl,
  }));
};

interface FormType {
  user: SelectableValue<string>;
  role: string;
}

interface AddUserModalType {
  isOpen: boolean;
  onDismiss: () => void;
}

export const AddUserModal = ({ isOpen, onDismiss }: AddUserModalType) => {
  const styles = useStyles2(getStyles);

  const [addUser] = useMutation<AddUserMutation>(ADD_USER, {
    refetchQueries: [GET_USERS],
    ignoreResults: true,
    onError: () => {
      getAppEvents().publish({
        type: AppEvents.alertError.name,
        payload: [`Error: failed to add user`],
      });
    },
  });

  const {
    control,
    handleSubmit,
    formState: { isValid },
  } = useForm<FormType>();

  const onSubmit: SubmitHandler<FormType> = (formData) => {
    const { role, user } = formData;
    addUser({
      variables: {
        input: {
          name: user.value,
          role,
        },
      },
    });
    onDismiss();
  };

  return (
    <Modal isOpen={isOpen} title={'Add User?'} onDismiss={onDismiss}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Field>
          <Controller
            name="user"
            control={control}
            rules={{ required: true }}
            render={({ field: { ref, ...field } }) => (
              <AsyncSelect
                {...field}
                loadOptions={loadUserOptions}
                allowCustomValue
                placeholder="Search for a username"
              />
            )}
          />
        </Field>
        <Field>
          <Controller
            name="role"
            control={control}
            rules={{ required: true }}
            render={({ field: { ref, ...field } }) => (
              <RadioButtonList
                {...field}
                options={[
                  { label: 'Reader', value: Role.Reader, description: 'Viewing permissions. No edit access.' },
                  {
                    label: 'Writer',
                    value: Role.Writer,
                    description: 'Viewing permissions, plus non-destructive edit access. Recommended for most users.',
                  },
                  {
                    label: 'Administrator',
                    value: Role.Administrator,
                    description: 'Viewing permissions, plus full edit access.',
                  },
                ]}
                className={styles.radio}
              />
            )}
          />
        </Field>
        <Modal.ButtonRow>
          <Button type="submit" variant="success" icon="user" disabled={!isValid}>
            Add User
          </Button>
        </Modal.ButtonRow>
      </form>
    </Modal>
  );
};

const getStyles = (theme: GrafanaTheme2) => ({
  radio: css({
    marginTop: theme.spacing(1),
    label: {
      paddingTop: theme.spacing(1),
      borderTop: `1px solid ${theme.colors.border.weak}`,
      ':last-of-type': {
        paddingBottom: theme.spacing(1),
        borderBottom: `1px solid ${theme.colors.border.weak}`,
      },
    },
  }),
});
