import { SceneQueryRunner } from '@grafana/scenes';
import { createInfinityQuery, getInfinityDatasource } from 'datasources/infinity';

export const getSourcesCountQueryRunner = (apiUrl: string) =>
  new SceneQueryRunner({
    datasource: getInfinityDatasource(),
    queries: [
      createInfinityQuery({
        refId: 'A',
        apiUrl,
        queryBody: `
          query getSources($filters: SourceFilters, $first: Int, $after: Int) {
            sources(filters: $filters, first: $first, after: $after) {
              metadata {
                totalCount
              }
            }
          }
        `,
        variables: {
          filters: {
            name: '${source}',
            type: '${type}',
            groupId: '${group}',
          },
          first: '$sourceFirst',
          after: '$sourceAfter',
        },
        overrides: {
          base: {
            root_selector: 'data.sources',
          },
        },
      }),
    ],
  });
