import { Alert, ErrorBoundary, LoadingPlaceholder } from '@grafana/ui';
import { Suspense, useDeferredValue } from 'react';

import { SourceCardGrid } from './SourceCardGrid';

interface SourceCardGridType {
  source: string;
  type: string;
  group: string;
  excludeK8sSources: string;
  first: string;
  after: string;
}

export const SourceCardGridContainer = ({
  source,
  type,
  group,
  excludeK8sSources,
  first,
  after,
}: SourceCardGridType) => {
  const deferredSource = useDeferredValue(source);
  const deferredType = useDeferredValue(type);
  const deferredGroup = useDeferredValue(group);
  const deferredExcludeK8sSources = useDeferredValue(excludeK8sSources);
  const deferredFirst = useDeferredValue(first);
  const deferredAfter = useDeferredValue(after);

  return (
    <ErrorBoundary>
      {({ error }) => {
        if (error) {
          return (
            <Alert
              title="Error: failed to load sources"
              onRemove={() => window.location.reload()}
              buttonContent="Reload"
            >
              {error.message}
            </Alert>
          );
        }
        return (
          <Suspense fallback={<LoadingPlaceholder text="Loading..." />}>
            <SourceCardGrid
              source={deferredSource}
              type={deferredType}
              group={deferredGroup}
              excludeK8sSources={deferredExcludeK8sSources}
              first={deferredFirst}
              after={deferredAfter}
            />
          </Suspense>
        );
      }}
    </ErrorBoundary>
  );
};
