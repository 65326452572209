import { useSuspenseQuery } from '@apollo/client';
import { css } from '@emotion/css';
import { Text, useStyles2 } from '@grafana/ui';
import type { GetVersionsForNamespacesQuery, Source, Version } from '__generated__/graphql';
import { useMemo } from 'react';

import { K8sSummaryCard } from './K8sSummaryCard';
import { GET_VERSIONS_FOR_NAMESPACES } from './K8sSummaryCardQueries';

interface K8sSummaryCardGridType {
  cluster: string;
  namespace: string;
  image: string;
  tankaTeam: string;
  githubTeam: string;
}

export const K8sSummaryCardGrid = ({ cluster, namespace, image, tankaTeam, githubTeam }: K8sSummaryCardGridType) => {
  const styles = useStyles2(getStyles);

  const { data } = useSuspenseQuery<GetVersionsForNamespacesQuery>(GET_VERSIONS_FOR_NAMESPACES, {
    variables: {
      filters: {
        clusterId: cluster,
        ...(namespace !== '0' ? { namespaceId: namespace } : {}),
        ...(tankaTeam ? { tankaTeam } : {}),
        ...(githubTeam ? { githubTeam } : {}),
      },
    },
    fetchPolicy: 'cache-and-network',
  });

  const versions = data.k8sNamespaces.response.reduce((acc, namespace) => {
    const seen = new Set(acc.map((v) => v.id));
    const newVersions = namespace.versions.filter((version) => !seen.has(version.id));
    return [...acc, ...newVersions] as Version[];
  }, [] as Version[]);

  const sortedVersions = useMemo(() => {
    return versions.sort((a, b) => {
      if (a.totalCveCounts.critical !== b.totalCveCounts.critical) {
        return b.totalCveCounts.critical - a.totalCveCounts.critical;
      } else if (a.totalCveCounts.high !== b.totalCveCounts.high) {
        return b.totalCveCounts.high - a.totalCveCounts.high;
      } else if (a.totalCveCounts.medium !== b.totalCveCounts.medium) {
        return b.totalCveCounts.medium - a.totalCveCounts.medium;
      } else {
        return b.totalCveCounts.low - a.totalCveCounts.low;
      }
    });
  }, [versions]);

  const images = useMemo(
    () => sortedVersions.filter((v) => `${v.source.name}:${v.version}`.includes(image)),
    [image, sortedVersions]
  );

  return (
    <div className={styles.gridContainer}>
      {images.length ? (
        images.map((version, index) => (
          <K8sSummaryCard
            clusterId={cluster}
            version={version as Version}
            source={version.source as Source}
            key={index}
          />
        ))
      ) : (
        <Text italic color="secondary">
          No images
        </Text>
      )}
    </div>
  );
};

const getStyles = () => ({
  gridContainer: css({
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(680px, 1fr))',
    gap: '12px',
  }),
});
