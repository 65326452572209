import {
  SceneObjectState,
  SceneObjectBase,
  SceneComponentProps,
  sceneGraph,
  VariableDependencyConfig,
} from '@grafana/scenes';
import { CompareContainer } from 'components/Compare';

interface CompareState extends SceneObjectState {
  cve: string;
  severity: string;
  sourceID: string;
}

export class Compare extends SceneObjectBase<CompareState> {
  static Component = CompareRenderer;

  protected _variableDependency = new VariableDependencyConfig(this, {
    statePaths: ['cve', 'severity'],
  });
}

function CompareRenderer({ model }: SceneComponentProps<Compare>) {
  const { cve, severity, sourceID } = model.useState();

  return (
    <CompareContainer
      sourceID={sourceID}
      cve={sceneGraph.interpolate(model, cve)}
      severity={sceneGraph.interpolate(model, severity)}
    />
  );
}
