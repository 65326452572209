import { useQuery } from '@apollo/client';
import { Alert } from '@grafana/ui';
import { GetSourceGroupsQuery } from '__generated__/graphql';

import { GET_SOURCE_GROUPS } from './FederalBannerQueries';

interface FederalBannerType {
  sourceID: string;
}

const FEDERAL_GROUP = 'federal';

export const FederalBanner = ({ sourceID }: FederalBannerType) => {
  const { data, loading, error } = useQuery<GetSourceGroupsQuery>(GET_SOURCE_GROUPS, {
    variables: {
      id: sourceID,
    },
  });

  if (loading || error) {
    return null;
  }

  const groups = data!.source.groups.map((group) => group.name);

  return groups.includes(FEDERAL_GROUP) ? (
    <Alert title="This is a FedRAMP Product!" severity="warning" bottomSpacing={0}>
      Please address all CVEs with a fix available.
    </Alert>
  ) : null;
};
