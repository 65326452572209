import { renderMarkdown } from '@grafana/data';
import { LinkButton, Box, Stack, Text } from '@grafana/ui';
import { CveException } from '__generated__/graphql';
import { formatDate } from 'components/utils';
import { CVE_DETAILS_ROUTE } from 'shared/constants/routes/appRoutes';

interface ExceptionDetailsDescriptionType {
  exception: CveException;
}

export const ExceptionDetailsDescription = ({ exception }: ExceptionDetailsDescriptionType) => {
  return (
    <Stack direction="column" grow={1}>
      <Box backgroundColor="canvas" borderColor="strong" borderStyle="solid" padding={1.5}>
        <Box marginBottom={1} display="flex" direction="column">
          <Text element="h3">Details</Text>
        </Box>
        <span
          className="markdown-html"
          // nosemgrep: typescript.react.security.audit.react-dangerouslysetinnerhtml.react-dangerouslysetinnerhtml
          dangerouslySetInnerHTML={{ __html: renderMarkdown(exception.description) }}
        />
        <Box display="flex" justifyContent="space-between" marginTop={2} gap={2} alignItems="center">
          <Text variant="h5" italic>
            Created on {formatDate(new Date(exception.createdOn), false)} by {exception.team}
          </Text>
          <LinkButton href={`${CVE_DETAILS_ROUTE}/${exception.cve.cve}`} variant="primary">
            Go to CVE
          </LinkButton>
        </Box>
      </Box>
    </Stack>
  );
};
