import { useQuery } from '@apollo/client';
import { LinkButton } from '@grafana/ui';
import { GetSourceHeaderQuery } from '__generated__/graphql';
import { GITHUB_PREFIX } from 'shared/constants/routes/externalRoutes';

import { GET_SOURCE_HEADER } from './SourceHeaderQueries';

interface SourceHeaderControlsType {
  sourceID: string;
}

export const SourceHeaderControls = ({ sourceID }: SourceHeaderControlsType) => {
  const { data, loading, error } = useQuery<GetSourceHeaderQuery>(GET_SOURCE_HEADER, {
    variables: {
      id: sourceID,
    },
  });

  if (loading || error) {
    return null;
  }

  const { name, origin } = data!.source;

  if (origin.toLowerCase() !== 'github') {
    return null;
  }

  return (
    <LinkButton
      variant="primary"
      size="sm"
      icon="github"
      href={GITHUB_PREFIX + name}
      target="_blank"
      rel="noopener noreferrer"
    >
      View on Github
    </LinkButton>
  );
};
