import {
  EmbeddedScene,
  QueryVariable,
  SceneAppPage,
  SceneFlexItem,
  SceneFlexLayout,
  SceneVariableSet,
  VariableValueSelectors,
} from '@grafana/scenes';
import { VariableHide } from '@grafana/schema';
import { Icon } from '@grafana/ui';
import { TextInputVariable } from 'controls/TextInputVariable';
import { createInfinityVariable, getInfinityDatasource } from 'datasources/infinity';
import { K8S_SCANNING_ROUTE } from 'shared/constants/routes/appRoutes';
import { K8S_LOGO_SVG } from 'shared/constants/routes/imageRoutes';

import { getK8sClusterDrilldown } from './getK8sClusterDrilldown';
import { getClusterCvesTable } from './panels';
import { getCveCountsPerClusterQueryRunner } from './queryRunners';

interface GetK8sScanningPageType {
  apiUrl: string;
}

export const getK8sScanningPage = ({ apiUrl }: GetK8sScanningPageType) => {
  const nameVariable = new TextInputVariable({
    name: 'name',
    placeholder: 'Search Clusters',
    prefix: <Icon name="search" />,
    width: 40,
    hide: VariableHide.hideLabel,
  });

  const tankaTeamVariable = new QueryVariable({
    name: 'tankaTeam',
    label: 'Tanka Team',
    datasource: getInfinityDatasource(),
    placeholder: 'Select Tanka Team',
    includeAll: true,
    defaultToAll: true,
    allValue: 'ALL',
    value: '$__all',
    query: createInfinityVariable({
      refId: 'variable-tanka-team',
      apiUrl,
      queryBody: `
        query getTeams {
          teams {
            __value: tankaTeam
          }
        }
      `,
      overrides: {
        base: {
          root_selector: 'data.teams',
        },
      },
    }),
  });
  const githubTeamVariable = new QueryVariable({
    name: 'githubTeam',
    label: 'GitHub Team',
    datasource: getInfinityDatasource(),
    placeholder: 'Select GitHub Team',
    includeAll: true,
    defaultToAll: true,
    allValue: 'ALL',
    value: '$__all',
    query: createInfinityVariable({
      refId: 'variable-github-team',
      apiUrl,
      queryBody: `
        query getTeams {
          teams {
            __value: githubTeam
          }
        }
      `,
      overrides: {
        base: {
          root_selector: 'data.teams',
        },
      },
    }),
  });

  return new SceneAppPage({
    title: 'Kubernetes',
    titleImg: K8S_LOGO_SVG,
    key: 'k8sScanningPage',
    url: K8S_SCANNING_ROUTE,
    getScene: () => {
      return new EmbeddedScene({
        key: 'k8sScanning',
        $variables: new SceneVariableSet({
          variables: [nameVariable, tankaTeamVariable, githubTeamVariable],
        }),
        body: new SceneFlexLayout({
          direction: 'column',
          children: [
            new SceneFlexLayout({
              direction: 'column',
              width: '100%',
              height: '100%',
              children: [
                new SceneFlexItem({
                  $data: getCveCountsPerClusterQueryRunner(apiUrl),
                  body: getClusterCvesTable(),
                }),
              ],
            }),
          ],
        }),
        controls: [new VariableValueSelectors({})],
      });
    },
    drilldowns: [
      {
        routePath: `${K8S_SCANNING_ROUTE}/:clusterID`,
        getPage(routeMatch, parent) {
          const clusterID = routeMatch.params.clusterID;
          return getK8sClusterDrilldown({ apiUrl, clusterID, parent });
        },
      },
    ],
  });
};
