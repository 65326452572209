import { EmbeddedScene, SceneAppPage, SceneFlexLayout, SceneReactObject } from '@grafana/scenes';
import { ManageSource } from 'components/ManageSource';
import { SOURCES_ROUTE } from 'shared/constants/routes/appRoutes';

interface GetManageTabType {
  sourceID: string;
}

export const getManageTab = ({ sourceID }: GetManageTabType) =>
  new SceneAppPage({
    title: 'Manage',
    titleIcon: 'cog',
    key: 'manageTab',
    url: `${SOURCES_ROUTE}/${sourceID}/manage`,
    getScene: () => {
      return new EmbeddedScene({
        key: 'manage',
        body: new SceneFlexLayout({
          direction: 'column',
          children: [
            new SceneReactObject({
              component: () => <ManageSource sourceID={sourceID} />,
            }),
          ],
        }),
      });
    },
  });
