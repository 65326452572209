import { css } from '@emotion/css';
import { Box, Icon, Stack, Tag, Text, TextLink, Tooltip, useStyles2, useTheme2 } from '@grafana/ui';

import { BaseTable } from '../base';
import { looseSemverSortTagsDesc, formatDate } from '../utils';

import { ReportRow, addDaysToDate } from './BuildReleasePlan';

interface PlanReleaseTableType {
  rows: { [pkg: string]: ReportRow };
  tags: string[];
}

export const PlanReleaseTable = ({ rows, tags }: PlanReleaseTableType) => {
  const styles = useStyles2(getStyles);
  const theme = useTheme2();

  const sortedTags = looseSemverSortTagsDesc(tags);

  return (
    <Stack direction="column">
      {Object.keys(rows).length ? (
        <div className={styles.scrollContainer}>
          <BaseTable theme={theme}>
            <thead>
              <tr>
                <th>Package</th>
                <th>Target</th>
                <th className={styles.tags}>Fixed In</th>
                {sortedTags.map((tag, index) => (
                  <th key={index} className={styles.tags}>
                    {tag}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {Object.entries(rows).map(([pkg, metadata], index) => (
                <tr key={index}>
                  <td>
                    <Stack direction="row" alignItems="center" justifyContent="flex-start">
                      <Tag name={pkg} />
                      <Tooltip
                        interactive
                        placement="right"
                        content={
                          <Stack direction="column">
                            {metadata.cves.map((c, index) =>
                              c.cve.length ? (
                                <span key={index}>
                                  <TextLink href={c.url} external inline>
                                    {c.cve}
                                  </TextLink>
                                  <Text color="secondary">
                                    {' '}
                                    (Out of SLO on {formatDate(addDaysToDate(new Date(), metadata.sloRemaining), false)}
                                    )
                                  </Text>
                                </span>
                              ) : (
                                <Text italic color="secondary" key={index}>
                                  Unknown CVE
                                </Text>
                              )
                            )}
                          </Stack>
                        }
                      >
                        <Icon name="exclamation-circle" size="sm" />
                      </Tooltip>
                    </Stack>
                  </td>
                  <td>
                    {metadata.target ? (
                      <Text>{metadata.target}</Text>
                    ) : (
                      <Text italic color="secondary">
                        Unknown Target
                      </Text>
                    )}
                  </td>
                  <td className={styles.tags}>
                    {metadata.fixedIn ? (
                      <Text color="success">{metadata.fixedIn}</Text>
                    ) : (
                      <Text italic color="secondary">
                        Unknown
                      </Text>
                    )}
                  </td>
                  {sortedTags.map((tag, index) => (
                    <td className={styles.tags} key={index}>
                      {metadata.installedVersions[tag] ? (
                        <Text color="error">{metadata.installedVersions[tag]}</Text>
                      ) : (
                        <Text color="success">
                          <Icon name="check-circle" />
                        </Text>
                      )}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </BaseTable>
        </div>
      ) : (
        <Box margin={2}>
          <Stack justifyContent="center" alignItems="center">
            <Text italic color="secondary" variant="h5">
              No action required
            </Text>
          </Stack>
        </Box>
      )}
    </Stack>
  );
};

const getStyles = () => ({
  scrollContainer: css({
    overflowX: 'auto',
    '@media (max-width: 720px)': {
      overflowX: 'scroll',
    },
  }),
  commaSeparated: css({
    '&:not(:last-child)::after': {
      content: '", "',
    },
  }),
  cvesCol: css({
    maxWidth: '380px',
  }),
  tags: css({
    textAlign: 'center',
    minWidth: '100px',
  }),
});
