import pluginJson from './plugin.json';

export const PLUGIN_BASE_URL = `/a/${pluginJson.id}`;

export const ROUTES = {
  Home: '/',
  Insights: '/insights',
  WithTabs: 'page-with-tabs',
  WithDrilldown: 'page-with-drilldown',
  HelloWorld: 'hello-world',
} as const;

export const DATASOURCE_REF = {
  uid: 'gdev-testdata',
  type: 'testdata',
};
