import { TypedDocumentNode, gql } from '@apollo/client';
import { GetCveExceptionAlertQuery, GetCveExceptionAlertQueryVariables } from '__generated__/graphql';

export const GET_CVE_EXCEPTION_ALERT: TypedDocumentNode<GetCveExceptionAlertQuery, GetCveExceptionAlertQueryVariables> =
  gql`
    query getCveExceptionAlert($filters: CveExceptionFilters) {
      cveExceptions(filters: $filters) {
        metadata {
          totalCount
        }
      }
    }
  `;
