import React from 'react';
import SVG from 'react-inlinesvg';
import { css } from '@emotion/css';

import { GrafanaTheme2 } from '@grafana/data';
import { config, PluginPage } from '@grafana/runtime';
import { LinkButton, useStyles2 } from '@grafana/ui';

import { ContentCard } from '@/components/ContentCard';
import { QuickLink } from '@/components/QuickLink';
import { testIds } from '@/components/testIds';
import { externalLinks, Icons, paths, phrases } from '@/constants';
import { useBillingDashboardPanel, useBillingDashboardUrl } from '@/hooks/dashboards';
import { getCommonStyles } from '@/pages/common-styles';

const getStyles = (theme: GrafanaTheme2) => {
  return {
    ...getCommonStyles(theme),
    optimizeBody: css({
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(2),
    }),
  };
};
export const Traces = () => {
  const styles = useStyles2(getStyles);
  const billingDashboardUrl = useBillingDashboardUrl();
  const billableTracesPanel = useBillingDashboardPanel('billableTracesUsage');
  const usageGroupsPanel = useBillingDashboardPanel('activeSeriesPerUsageGroup');
  const ingestedTracesPanel = useBillingDashboardPanel('totalIngestedTracesByDay');

  const adaptiveTracesPluginEnabled = config.apps
    ? Object.keys(config.apps).some((app) => app === 'grafana-adaptivetraces-app')
    : false;

  const attributionPluginEnabled = config.apps
    ? Object.keys(config.apps).some((app) => app === 'grafana-attributions-app')
    : false;

  const isAdmin = config.bootData.user.orgRole === 'Admin' ? true : false;
  return (
    <PluginPage
      renderTitle={() => <h1>Traces cost management</h1>}
      subTitle={'Analyze, monitor, and optimize your spend on Grafana Cloud Traces'}
    >
      <div className={styles.container}>
        <ContentCard.Card>
          <ContentCard.Header>Inspect</ContentCard.Header>
          <ContentCard.SubHeader>{phrases.inspect}</ContentCard.SubHeader>
          <ContentCard.Figure>
            <SVG src={Icons.inspect} width={undefined} height={undefined} />
          </ContentCard.Figure>
          <ContentCard.Body>
            <QuickLink.Container className={styles.quickLinkGrid}>
              <QuickLink.Link href={billableTracesPanel?.panelUrl || billingDashboardUrl}>
                Billing dashboard - {billableTracesPanel?.panelTitleDisplay}
              </QuickLink.Link>
              <QuickLink.Link href={ingestedTracesPanel?.panelUrl || billingDashboardUrl}>
                Billing dashboard - {ingestedTracesPanel?.panelTitleDisplay}
              </QuickLink.Link>
            </QuickLink.Container>
          </ContentCard.Body>
        </ContentCard.Card>
        <ContentCard.Card>
          <ContentCard.Header>Attribute</ContentCard.Header>
          <ContentCard.SubHeader>{phrases.attribute}</ContentCard.SubHeader>
          <ContentCard.Figure>
            <SVG src={Icons.attribution} width={undefined} height={undefined} />
          </ContentCard.Figure>
          <ContentCard.Body>
            <QuickLink.Container className={styles.quickLinkGrid}>
              <QuickLink.Link href={usageGroupsPanel?.panelUrl || billingDashboardUrl}>
                Billing dashboard - {usageGroupsPanel?.panelTitleDisplay}
              </QuickLink.Link>
              {isAdmin && attributionPluginEnabled ? (
                <LinkButton data-testid={testIds.attributions.pluginLink} href={paths.attributions} variant={'primary'}>
                  View usage attribution reports
                </LinkButton>
              ) : (
                <QuickLink.ExternalLink href={externalLinks.costAttributionReport}>
                  Generate usage attribution reports
                </QuickLink.ExternalLink>
              )}
            </QuickLink.Container>
          </ContentCard.Body>
        </ContentCard.Card>
        <ContentCard.Card>
          <ContentCard.Header>Optimize</ContentCard.Header>
          <ContentCard.SubHeader>{phrases.optimize.traces}</ContentCard.SubHeader>
          <ContentCard.Figure>
            <SVG src={Icons.optimize} width={undefined} height={undefined} />
          </ContentCard.Figure>
          <ContentCard.Body className={styles.optimizeBody}>
            <span className={styles.appPluginLinkContainer}>
              <LinkButton
                data-testid={testIds.adaptiveTraces.pluginLink}
                disabled={!adaptiveTracesPluginEnabled}
                href={paths.adaptiveTraces}
                variant={'primary'}
              >
                Adaptive Traces
              </LinkButton>
              <span className={styles.secondaryText}>
                {adaptiveTracesPluginEnabled
                  ? 'Manage your trace sampling policies with Adaptive Traces'
                  : 'The Adaptive Trace plugin is not installed.'}
              </span>
            </span>
            <QuickLink.ExternalLink href={externalLinks.tracesReduceCosts}>
              How to reduce cost in Traces
            </QuickLink.ExternalLink>
          </ContentCard.Body>
        </ContentCard.Card>
        <ContentCard.Card>
          <ContentCard.Header>Monitor</ContentCard.Header>
          <ContentCard.SubHeader>{phrases.monitoring}</ContentCard.SubHeader>
          <ContentCard.Figure>
            <SVG src={Icons.monitor} width={undefined} height={undefined} />
          </ContentCard.Figure>
          <ContentCard.Body>
            <QuickLink.ExternalLink href={externalLinks.setupUsageAlerts}>
              Set up usage alerts for traces
            </QuickLink.ExternalLink>
          </ContentCard.Body>
        </ContentCard.Card>
      </div>
    </PluginPage>
  );
};
