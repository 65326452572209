import { css } from '@emotion/css';
import { GrafanaTheme2 } from '@grafana/data';
import { useStyles2, Text, Box } from '@grafana/ui';

interface DaysToFixType {
  sloRemaining: number;
}

export const DaysToFix = ({ sloRemaining }: DaysToFixType) => {
  const styles = useStyles2(getStyles);

  return (
    <Box marginBottom={2} padding={1.5} backgroundColor="canvas" borderColor="strong" borderStyle="solid">
      <Text element="h2" variant="h5">
        Days Remaining Inside SLO
      </Text>
      <Box display="flex" justifyContent="center" alignItems="center" marginTop={1}>
        <span className={styles.color(sloRemaining)}>
          <Text element="h2" variant="h2">
            {sloRemaining === 9999 ? 'No SLO' : sloRemaining}
          </Text>
        </span>
      </Box>
    </Box>
  );
};

const getStyles = (theme: GrafanaTheme2) => ({
  color: (sloRemaining: number) => {
    if (sloRemaining < 7) {
      return css({ color: theme.visualization.getColorByName('dark-red') });
    } else if (sloRemaining < 14) {
      return css({ color: theme.visualization.getColorByName('dark-orange') });
    } else if (sloRemaining < 30) {
      return css({ color: theme.visualization.getColorByName('dark-yellow') });
    } else {
      return css({ color: theme.visualization.getColorByName('dark-green') });
    }
  },
});
