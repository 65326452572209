import { useSuspenseQuery } from '@apollo/client';
import { css } from '@emotion/css';
import { EmptySearchResult, useStyles2 } from '@grafana/ui';
import type { GetVersionsWithSloQuery, Source, Version } from '__generated__/graphql';
import { useMemo } from 'react';

import { looseSemverSort } from '../utils';

import { VersionSummaryCard } from './VersionSummaryCard';
import { GET_VERSIONS_WITH_SLO } from './VersionSummaryCardQueries';

interface VersionSummaryGridType {
  sourceID: string;
  version: string;
}

export const VersionSummaryCardGrid = ({ sourceID, version }: VersionSummaryGridType) => {
  const styles = useStyles2(getStyles);

  const { data } = useSuspenseQuery<GetVersionsWithSloQuery>(GET_VERSIONS_WITH_SLO, {
    variables: {
      id: sourceID,
    },
    fetchPolicy: 'cache-and-network',
  });

  const versions = useMemo(
    () => data.source.versions.filter((v) => v.version.includes(version)),
    [version, data.source.versions]
  );

  const semverSortedVersions = useMemo(() => looseSemverSort(versions as Version[]), [versions]);

  return (
    <div className={styles.gridContainer}>
      {semverSortedVersions.length ? (
        semverSortedVersions.map((version, index) => (
          <VersionSummaryCard version={version} source={data.source as Source} key={index} secrets={version.secrets} />
        ))
      ) : (
        <EmptySearchResult>No versions were found.</EmptySearchResult>
      )}
    </div>
  );
};

const getStyles = () => ({
  gridContainer: css({
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(680px, 1fr))',
    gap: '12px',
  }),
});
