import { useMutation } from '@apollo/client';
import { AppEvents } from '@grafana/data';
import { getAppEvents } from '@grafana/runtime';
import { Box, Button, Stack } from '@grafana/ui';
import {
  ContainerSourceConfig,
  ModifyContainerSourceConfigInput,
  ModifyContainerSourceConfigMutation,
  Source,
} from '__generated__/graphql';
import { SubmitHandler, useForm } from 'react-hook-form';
import { REPOSITORIES } from 'shared/constants/sourceRepositories';

import {
  AllowedFlavorsField,
  AutoArchiveField,
  BlocklistedFlavorsField,
  ContainerImageNameField,
  ContainerRepositoryField,
  LastTagsToScanField,
  MajorVersionsField,
  MinorVersionsField,
  PatchVersionsField,
  UseSemverPatternField,
} from '../ConfigureSource/Fields';
import { ContainerScanningConfig, SharedScanningConfig } from '../ConfigureSource/types';

import { MODIFY_CONTAINER_SOURCE_CONFIG } from './ManageSourceMutations';

export interface ManageContainerForm extends ContainerScanningConfig, SharedScanningConfig {}

interface ManageContainerScanningType {
  source: Source;
}

export const ManageContainerScanning = ({ source }: ManageContainerScanningType) => {
  const [modifyContainerSourceConfig] = useMutation<ModifyContainerSourceConfigMutation>(
    MODIFY_CONTAINER_SOURCE_CONFIG,
    {
      ignoreResults: true,
      onError: () => {
        getAppEvents().publish({
          type: AppEvents.alertError.name,
          payload: [`Error: failed to update scanning config`],
        });
      },
      onCompleted: () => {
        getAppEvents().publish({
          type: AppEvents.alertSuccess.name,
          payload: [`Success: updated scanning config`],
        });
      },
    }
  );

  const config = source.config as ContainerSourceConfig;

  const { control, handleSubmit, formState, watch } = useForm<ManageContainerForm>({
    defaultValues: {
      imageName: config.imageName,
      repository: config.repository,
      autoArchive: config.autoArchive,
      majorReleases: config.majorReleases,
      minorReleases: config.minorReleases,
      patchReleases: config.patchReleases,
      lastTagsToScan: config.lastTagsToScan,
      useSemverPattern: !config.useLastNTags,
      allowedFlavors: config.allowedFlavors,
      blocklistedFlavors: config.blocklistedFlavors,
    },
  });

  const repository = watch('repository');
  const useSemverPattern = watch('useSemverPattern');

  const onSubmit: SubmitHandler<ManageContainerForm> = (data) => {
    const payload: ModifyContainerSourceConfigInput = {
      sourceId: source.id,
      imageName: data.imageName,
      repository: data.repository,
      autoArchive: data.autoArchive,
      majorReleases: data.majorReleases,
      minorReleases: data.minorReleases,
      patchReleases: data.patchReleases,
      lastTagsToScan: data.lastTagsToScan,
      useLastNTags: data.repository !== REPOSITORIES.DOCKER_HUB && !data.useSemverPattern,
      allowedFlavors: data.allowedFlavors,
      blocklistedFlavors: data.blocklistedFlavors,
    };

    modifyContainerSourceConfig({
      variables: {
        input: payload,
      },
    });
  };

  const showSemver = repository === REPOSITORIES.DOCKER_HUB || !repository || useSemverPattern;
  const showSemverSwitch = repository === REPOSITORIES.GCR_US || repository === REPOSITORIES.GAR_US;

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack direction="column">
          <ContainerImageNameField control={control} formState={formState} disabled />
          <ContainerRepositoryField control={control} formState={formState} disabled />
          {showSemverSwitch && <UseSemverPatternField control={control} formState={formState} disabled />}
          {showSemver ? (
            <Box marginBottom={1}>
              <MajorVersionsField control={control} formState={formState} required />
              <MinorVersionsField control={control} formState={formState} required />
              <PatchVersionsField control={control} formState={formState} required />
            </Box>
          ) : (
            <LastTagsToScanField control={control} formState={formState} required />
          )}
          <AllowedFlavorsField control={control} formState={formState} />
          <BlocklistedFlavorsField control={control} formState={formState} />
          <AutoArchiveField control={control} formState={formState} />
        </Stack>
        <Box marginTop={1}>
          <Button
            type="submit"
            size="md"
            disabled={!formState.isDirty}
            tooltip={!formState.isDirty ? 'No changes' : ''}
          >
            Save Scanning Configuration
          </Button>
        </Box>
      </form>
    </>
  );
};
