import { SceneQueryRunner } from '@grafana/scenes';
import { createInfinityQuery, getInfinityDatasource } from 'datasources/infinity';

export const getImpactedVersionsQueryRunner = (apiUrl: string, cve: string) =>
  new SceneQueryRunner({
    datasource: getInfinityDatasource(),
    queries: [
      createInfinityQuery({
        refId: 'A',
        apiUrl,
        queryBody: `
          query getImpactedVersions($filters: CveFilters, $first: Int, $after: Int) {
            cves(filters: $filters, first: $first, after: $after) {
              response {
                id
                versions {
                  id
                  version
                  isMostRecentVersion
                  source {
                    id
                    name
                    origin
                  }
                }
              }
            }
          }
        `,
        variables: {
          filters: {
            cve,
          },
          first: 1,
        },
        overrides: {
          base: {
            root_selector: 'data.cves.response[0].versions',
            columns: [
              {
                text: 'versionID',
                selector: 'id',
                type: 'string',
              },
              {
                text: 'version',
                selector: 'version',
                type: 'string',
              },
              {
                text: 'isMostRecentVersion',
                selector: 'isMostRecentVersion',
                type: 'boolean',
              },
              {
                text: 'name',
                selector: 'source.name',
                type: 'string',
              },
              {
                text: 'origin',
                selector: 'source.origin',
                type: 'string',
              },
              {
                text: 'sourceID',
                selector: 'source.id',
                type: 'string',
              },
            ],
          },
        },
      }),
    ],
  });
