import { Version } from '__generated__/graphql';
import { coerce, valid, rcompare } from 'semver';

const semverSort = (a: string, b: string): number => {
  const lcA = a.toLowerCase();
  const lcB = b.toLowerCase();

  const isSpecial = (v: string) => v.includes('latest') || v.includes('main') || v.includes('master');
  if (isSpecial(lcA) && !isSpecial(lcB)) {
    return -1;
  } else if (!isSpecial(lcA) && isSpecial(lcB)) {
    return 1;
  }

  const versionA = valid(lcA) || valid(coerce(lcA));
  const versionB = valid(lcB) || valid(coerce(lcB));
  if (versionA && versionB) {
    return rcompare(versionA, versionB);
  }

  return lcA.localeCompare(lcB);
};

export const looseSemverSort = (versions: Version[]): Version[] => {
  return versions.slice().sort((a, b) => semverSort(a.version, b.version));
};

export const looseSemverSortTagsDesc = (tags: string[]): string[] => {
  return tags.slice().sort(semverSort);
};
