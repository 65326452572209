import { AppRootProps } from '@grafana/data';
import { createContext, useContext, useMemo } from 'react';

// This is used to be able to retrieve the root plugin props anywhere inside the app.
export const PluginPropsContext = createContext<AppRootProps | null>(null);

export const usePluginProps = () => {
  const pluginProps = useContext(PluginPropsContext);

  return pluginProps;
};

export const usePluginMeta = () => {
  const pluginProps = usePluginProps();

  return pluginProps?.meta;
};

export const useApiUrl = () => {
  const pluginMeta = usePluginMeta();

  const apiUrl = useMemo(() => {
    return pluginMeta?.jsonData?.apiUrl ? `${pluginMeta.jsonData.apiUrl}/graphql/query` : null;
  }, [pluginMeta?.jsonData?.apiUrl]);

  return apiUrl || '';
};
