import { DataLinkClickEvent } from '@grafana/data';
import {
  PanelBuilders,
  SceneCanvasText,
  SceneDataTransformer,
  SceneFlexItem,
  SceneFlexLayout,
  SplitLayout,
} from '@grafana/scenes';
import { BarGaugeDisplayMode, BarGaugeValueMode, TableCellDisplayMode } from '@grafana/schema';
import { MISSING_SLO_MAPPING, SLO_REMAINING_THRESHOLDS } from 'shared/panelConstants';

import { getRelatedCvesTable } from '../panels';
import { getCvesWithSloQueryRunner } from '../queryRunners/getCvesWithSloQueryRunner';

export const getPackageSplitLayout = (apiUrl: string, sourceID: string, versionID: string) => {
  const packageUrlParam = new URLSearchParams(window.location.search).get('var-pkg');

  const packageSplitLayout = new SplitLayout({
    direction: 'row',
    initialSize: 0.3,
    $data: getCvesWithSloQueryRunner(apiUrl, versionID),
    primary: new SceneFlexItem({
      // This table references the splitter scene object and
      // can't be trivially pulled out to its own function
      body: PanelBuilders.table()
        .setData(
          new SceneDataTransformer({
            transformations: [
              {
                id: 'groupBy',
                options: {
                  fields: {
                    package: {
                      operation: 'groupby',
                    },
                    sloRemaining: {
                      aggregations: ['min'],
                      operation: 'aggregate',
                    },
                  },
                },
              },
              {
                id: 'organize',
                options: {
                  indexByName: {
                    package: 0,
                    'sloRemaining (min)': 1,
                  },
                  renameByName: {
                    package: 'Package',
                    'sloRemaining (min)': 'SLO Remaining',
                  },
                },
              },
              {
                id: 'sortBy',
                options: {
                  sort: [
                    {
                      field: 'SLO Remaining',
                      desc: false,
                    },
                  ],
                },
              },
            ],
          })
        )
        .setTitle('Vulnerable Packages')
        .setCustomFieldConfig('align', 'left')
        .setThresholds(SLO_REMAINING_THRESHOLDS)
        .setNoValue('No Vulnerable Packages')
        .setMin(0)
        .setMax(60)
        .setOverrides((overridesBuilder) => {
          overridesBuilder
            .matchFieldsWithName('Package')
            .overrideCustomFieldConfig('filterable', true)
            .overrideNoValue('No Package')
            .overrideLinks([
              {
                title: 'See CVEs related to ${__data.fields.Package}',
                url: '',
                onClick: (e: DataLinkClickEvent) => {
                  let pkg = e.origin.field.values.get(e.origin.rowIndex);
                  if (pkg === '') {
                    pkg = 'No Package';
                  }

                  packageSplitLayout.setState({
                    secondary: new SceneFlexItem({
                      minWidth: 500,
                      body: getRelatedCvesTable(pkg, sourceID, versionID),
                    }),
                  });
                },
              },
            ]);
          overridesBuilder
            .matchFieldsWithName('SLO Remaining')
            .overrideCustomFieldConfig('cellOptions', {
              type: TableCellDisplayMode.Gauge,
              mode: BarGaugeDisplayMode.Basic,
              valueDisplayMode: BarGaugeValueMode.Text,
            })
            .overrideUnit('days')
            .overrideMappings(MISSING_SLO_MAPPING);
        })
        .build(),
      minWidth: 200,
    }),
    secondary: new SceneFlexItem({
      minWidth: 500,
      body:
        packageUrlParam && packageUrlParam.length
          ? new SceneFlexLayout({
              children: [
                new SceneFlexItem({
                  minWidth: 500,
                  body: getRelatedCvesTable(packageUrlParam, sourceID, versionID),
                }),
              ],
            })
          : new SceneCanvasText({
              text: 'Select a package to see CVEs',
              fontSize: 20,
              align: 'center',
            }),
    }),
  });

  return packageSplitLayout;
};
