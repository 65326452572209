import {
  SceneObjectBase,
  SceneComponentProps,
  SceneObjectUrlValues,
  SceneObjectUrlSyncConfig,
  SceneVariable,
  SceneVariableState,
  VariableValue,
  VariableDependencyConfig,
  SceneObjectUrlSyncHandler,
  SceneVariableValueChangedEvent,
} from '@grafana/scenes';
import { Box, Switch } from '@grafana/ui';

export interface SwitchVariableState extends SceneVariableState {
  value: '0' | '1';
}

export class SwitchVariable extends SceneObjectBase<SwitchVariableState> implements SceneVariable<SwitchVariableState> {
  protected _urlSync: SceneObjectUrlSyncHandler;

  protected _variableDependency = new VariableDependencyConfig(this, {
    statePaths: ['value'],
  });

  public constructor(initialState: Partial<SwitchVariableState>) {
    super({
      value: '0',
      name: '',
      type: 'custom',
      ...initialState,
    });

    this._urlSync = new SceneObjectUrlSyncConfig(this, { keys: [this.getKey()] });
    this.toggle = this.toggle.bind(this);
  }

  public getValue(): VariableValue {
    return this.state.value;
  }

  // public validateAndUpdate(): Observable<ValidateAndUpdateResult> {
  //   const { value } = this.state;

  //   if (value === '1') {
  //     this.publishEvent(new SceneVariableValueChangedEvent(this), true);
  //   }

  //   return of({});
  // }

  private getKey(): string {
    if (this.state.skipUrlSync) {
      return '';
    }

    return `var-${this.state.name}`;
  }

  public getUrlState(): SceneObjectUrlValues {
    return { [this.getKey()]: this.state.value };
  }

  public updateFromUrl(values: SceneObjectUrlValues): void {
    const urlValue = values[this.getKey()];

    if (urlValue) {
      this.setState({ value: urlValue === '1' ? '1' : '0' });
      return;
    }

    this.setState({ value: this.state.value });
  }

  public toggle = (): void => {
    const newValue = this.state.value === '0' ? '1' : '0';
    this.setState({ value: newValue });
    this.publishEvent(new SceneVariableValueChangedEvent(this), true);
  };

  public static Component = ({ model }: SceneComponentProps<SwitchVariable>) => {
    const { value } = model.useState();

    return (
      <Box
        display="flex"
        alignItems="center"
        paddingX={1}
        borderColor="medium"
        borderRadius="default"
        borderStyle="solid"
        backgroundColor="canvas"
      >
        <Switch value={value === '1'} onChange={model.toggle} />
      </Box>
    );
  };
}
