import { TypedDocumentNode, gql } from '@apollo/client';
import { GetVersionsWithSloQuery, GetVersionsWithSloQueryVariables } from '__generated__/graphql';

export const GET_VERSIONS_WITH_SLO: TypedDocumentNode<GetVersionsWithSloQuery, GetVersionsWithSloQueryVariables> = gql`
  query getVersionsWithSlo($id: ID!) {
    source(id: $id) {
      id
      origin
      name
      versions {
        id
        version
        isMostRecentVersion
        latestScan
        trackSlos
        totalCveCounts {
          critical
          high
          medium
          low
        }
        secrets {
          id
        }
        totalCveCountsOutOfSlo {
          critical
          high
          medium
          low
        }
        lowestSloRemaining
      }
    }
  }
`;
