import { EmbeddedScene, SceneAppPage, SceneFlexLayout, SceneReactObject } from '@grafana/scenes';
import { ConfigureSource } from 'components/ConfigureSource';
import { TitleWithIcon } from 'components/TitleWithIcon';
import { CONFIGURE_SOURCE_ROUTE } from 'shared/constants/routes/appRoutes';

export const getConfigureSourcePage = () =>
  new SceneAppPage({
    title: 'Configure a New Source',
    renderTitle(title) {
      return TitleWithIcon({ title, icon: 'folder-plus', color: 'success' });
    },
    key: 'configureSourcePage',
    url: CONFIGURE_SOURCE_ROUTE,
    getScene: () => {
      return new EmbeddedScene({
        key: 'configureSource',
        body: new SceneFlexLayout({
          direction: 'column',
          children: [
            new SceneReactObject({
              component: () => <ConfigureSource />,
            }),
          ],
        }),
      });
    },
  });
