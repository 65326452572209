import { useQuery } from '@apollo/client';
import { Stack, Text } from '@grafana/ui';
import { GetK8sClusterHeaderQuery } from '__generated__/graphql';
import { K8S_LOGO_SVG } from 'shared/constants/routes/imageRoutes';

import { GET_K8S_CLUSTER_HEADER } from './K8sClusterHeaderQueries';

interface K8sClusterHeaderType {
  clusterID: string;
}

export const K8sClusterHeader = ({ clusterID }: K8sClusterHeaderType) => {
  const { data, loading, error } = useQuery<GetK8sClusterHeaderQuery>(GET_K8S_CLUSTER_HEADER, {
    variables: {
      id: clusterID,
    },
  });

  if (loading || error) {
    return null;
  }

  const { name, namespaces } = data!.k8sCluster;

  const totalVersions = namespaces.flatMap((namespace) => namespace.versions.map((version) => version.id));
  const uniqueVersions = [...new Set(totalVersions)];

  return (
    <Stack direction="column" gap={0.5}>
      <Stack justifyContent="flex-start" alignItems="center" gap={1}>
        <img src={K8S_LOGO_SVG} alt="logo" height="30px" width="30px" />
        <Text element="h1" variant="h2">
          {name}
        </Text>
      </Stack>
      <Stack direction="row" gap={1}>
        <Text color="secondary" variant="bodySmall">
          {namespaces.length} Namespace{namespaces.length === 1 ? '' : 's'} | {totalVersions.length} Image
          {totalVersions.length === 1 ? '' : 's'} | {uniqueVersions.length} Unique Image
          {uniqueVersions.length === 1 ? '' : 's'}
        </Text>
      </Stack>
    </Stack>
  );
};
