import { AppPlugin, EventBusSrv, PluginExtensionEventHelpers, type AppRootProps } from '@grafana/data';
import { config, sidecarServiceSingleton_EXPERIMENTAL } from '@grafana/runtime';
import { LoadingPlaceholder } from '@grafana/ui';
import React, { Suspense, lazy } from 'react';
import { Collectable } from 'utils/types';
import { eventBus, setEventBus } from 'utils/utils.routing';
import pluginJson from './plugin.json';
import { reportAppInteraction, UserInteraction } from 'utils/analytics';
import { AddCollectableEvent } from 'utils/events';

setEventBus(new EventBusSrv());

const LazyApp = lazy(() => import('./components/App/App'));

const App = (props: AppRootProps) => (
  <Suspense fallback={<LoadingPlaceholder text="" />}>
    <LazyApp {...props} />
  </Suspense>
);

export const plugin = new AppPlugin<{}>().setRootPage(App);

const title = 'Open Investigation';
const collectableIds: Set<string> = new Set();

function openApp(helpers?: PluginExtensionEventHelpers): Promise<void> {
  return new Promise((resolve) => {
    if (!sidecarServiceSingleton_EXPERIMENTAL.isAppOpened(pluginJson.id)) {
      // @ts-ignore
      if (sidecarServiceSingleton_EXPERIMENTAL.openAppV3) {
        // @ts-ignore
        sidecarServiceSingleton_EXPERIMENTAL.openAppV3({ pluginId: pluginJson.id, follow: true });
      } else {
        // TODO: the passed context is not used yet, switch to use `pluginContext`
        sidecarServiceSingleton_EXPERIMENTAL.openApp(pluginJson.id, helpers ? helpers.context : undefined);
      }
      // Give the app time to initialize
      setTimeout(resolve, 1000);
    } else {
      resolve();
    }
  });
}

if (config.featureToggles.appSidecar) {
  plugin.addLink({
    targets: ['grafana/commandpalette/action'],
    title: 'Open Investigations',
    description: 'Opens the investigations app',
    onClick: () => {
      openApp();
    },
  });

  plugin.addLink({
    title,
    description: title,
    targets: [
      'grafana-lokiexplore-app/metric-exploration/v1',
      'grafana-explore-metrics/exploration/v1',
      'grafana-pyroscope-app/exploration/v1',
    ],
    configure: (context: Collectable | undefined) => {
      if (!context) {
        return;
      }
      const isAdded = collectableIds.has(context.id);
      return {
        icon: 'panel-add',
        category: isAdded ? 'disabled' : 'enabled',
        description: 'Open Investigation',
      };
    },
    onClick: async (_, helpers) => {
      if (!helpers.context) {
        return;
      }
      await openApp(helpers);

      collectableIds.add(helpers.context.id);

      eventBus.publish(new AddCollectableEvent(helpers.context as Collectable));
      reportAppInteraction(UserInteraction.CollectablePanelAdded, { from: 'click' });
    },
  });
}
