import {
  EmbeddedScene,
  QueryVariable,
  SceneAppPage,
  SceneFlexItem,
  SceneFlexLayout,
  SceneVariableSet,
  VariableValueSelectors,
} from '@grafana/scenes';
import { VariableHide } from '@grafana/schema';
import { Icon } from '@grafana/ui';
import { TextInputVariable } from 'controls/TextInputVariable';
import { createInfinityVariable, getInfinityDatasource } from 'datasources/infinity';
import { K8S_SCANNING_ROUTE } from 'shared/constants/routes/appRoutes';

import { getNamespaceCvesTable } from './panels';
import { getCveCountsPerNamespaceQueryRunner } from './queryRunners';

interface GetNamespacesTabType {
  apiUrl: string;
  clusterID: string;
}

export const getNamespacesTab = ({ apiUrl, clusterID }: GetNamespacesTabType) => {
  const nameVariable = new TextInputVariable({
    name: 'name',
    placeholder: 'Search Namespaces',
    prefix: <Icon name="search" />,
    width: 40,
    hide: VariableHide.hideLabel,
  });

  const tankaTeamVariable = new QueryVariable({
    name: 'tankaTeam',
    label: 'Tanka Team',
    datasource: getInfinityDatasource(),
    placeholder: 'Select Tanka Team',
    includeAll: true,
    defaultToAll: true,
    allValue: 'ALL',
    value: '$__all',
    query: createInfinityVariable({
      refId: 'variable-tanka-team',
      apiUrl,
      queryBody: `
        query getTeams {
          teams {
            __value: tankaTeam
          }
        }
      `,
      overrides: {
        base: {
          root_selector: 'data.teams',
        },
      },
    }),
  });
  const githubTeamVariable = new QueryVariable({
    name: 'githubTeam',
    label: 'GitHub Team',
    datasource: getInfinityDatasource(),
    placeholder: 'Select GitHub Team',
    includeAll: true,
    defaultToAll: true,
    allValue: 'ALL',
    value: '$__all',
    query: createInfinityVariable({
      refId: 'variable-github-team',
      apiUrl,
      queryBody: `
        query getTeams {
          teams {
            __value: githubTeam
          }
        }
      `,
      overrides: {
        base: {
          root_selector: 'data.teams',
        },
      },
    }),
  });

  return new SceneAppPage({
    title: 'Namespaces',
    key: 'k8sNamespaces',
    url: `${K8S_SCANNING_ROUTE}/${clusterID}`,
    getScene: () => {
      return new EmbeddedScene({
        key: 'k8sNamespaceTab',
        $variables: new SceneVariableSet({
          variables: [nameVariable, tankaTeamVariable, githubTeamVariable],
        }),
        body: new SceneFlexLayout({
          direction: 'column',
          children: [
            new SceneFlexLayout({
              direction: 'column',
              width: '100%',
              height: '100%',
              children: [
                new SceneFlexItem({
                  $data: getCveCountsPerNamespaceQueryRunner(apiUrl, clusterID),
                  body: getNamespaceCvesTable(clusterID),
                }),
              ],
            }),
          ],
        }),
        controls: [new VariableValueSelectors({})],
      });
    },
  });
};
