import pluginJson from '../../../plugin.json';

export const PLUGIN_ID = pluginJson.id;

export const IMAGE_BASE_ROUTE = `public/plugins/${PLUGIN_ID}/assets/img`;
export const GRAFANA_LOGO_PNG = `${IMAGE_BASE_ROUTE}/grafana-icon.png`;
export const GITHUB_LOGO_SVG = `${IMAGE_BASE_ROUTE}/github.svg`;
export const DOCKER_LOGO_SVG = `${IMAGE_BASE_ROUTE}/docker-mark-blue.svg`;
export const GCR_LOGO_SVG = `${IMAGE_BASE_ROUTE}/gcr.svg`;
export const GAR_LOGO_SVG = `${IMAGE_BASE_ROUTE}/gar.svg`;
export const K8S_LOGO_SVG = `${IMAGE_BASE_ROUTE}/k8s.svg`;
export const ECR_LOGO_SVG = `${IMAGE_BASE_ROUTE}/ecr.svg`;
export const REDHAT_LOGO_SVG = `${IMAGE_BASE_ROUTE}/redhat.svg`;
export const REDPANDA_LOGO_SVG = `${IMAGE_BASE_ROUTE}/redpanda.svg`;
export const UPBOUND_LOGO_SVG = `${IMAGE_BASE_ROUTE}/upbound.svg`;
export const INTIGRITI_LOGO_SVG = `${IMAGE_BASE_ROUTE}/intigriti.svg`;
