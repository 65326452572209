import { css, cx } from '@emotion/css';
import { GrafanaTheme2 } from '@grafana/data';
import { Tag, Text, TextLink, useStyles2 } from '@grafana/ui';
import { Issue } from '__generated__/graphql';
import { useNavigate } from 'react-router-dom-v5-compat';
import { CVES_ROUTE } from 'shared/constants/routes/appRoutes';

import { capitalizeFirstLetter, formatDate } from '../utils';

const getNVDLink = (cvssV3: string) =>
  `https://nvd.nist.gov/vuln-metrics/cvss/v3-calculator?vector=${cvssV3.replace(/CVSS:3\.[01]\//, '')}&version=${
    cvssV3.startsWith('CVSS:3.1') ? '3.1' : '3.0'
  }`;

interface IssueReportTableType {
  issue: Issue;
}

const UNKNOWN = 'unknown';

export const IssueReportTable = ({ issue }: IssueReportTableType) => {
  const styles = useStyles2(getStyles);
  const navigate = useNavigate();

  const publishedDate = issue.cve.publishedDate ? formatDate(new Date(issue.cve.publishedDate)) : UNKNOWN;
  const lastModifiedDate = issue.cve.lastModifiedDate ? formatDate(new Date(issue.cve.lastModifiedDate)) : UNKNOWN;

  return (
    <table className={styles.table}>
      <tbody>
        <tr>
          <th>Package</th>
          <td>
            <Tag name={issue.package} onClick={() => navigate(`${CVES_ROUTE}?var-package=${issue.package}`)} />
          </td>
        </tr>
        {issue.target && (
          <tr>
            <th>Target</th>
            <td>{issue.target}</td>
          </tr>
        )}
        <tr>
          <th>Severity</th>
          <td className={styles.severity(issue.cve.severity)}>{issue.cve.severity}</td>
        </tr>
        <tr>
          <th>CVSS</th>
          <td>{issue.cve.cvssScore}</td>
        </tr>
        <tr>
          <th>CVSS V3</th>
          <td>
            {issue.cve.cvssV3 ? (
              <TextLink href={getNVDLink(issue.cve.cvssV3)} inline={false} external>
                {issue.cve.cvssV3}
              </TextLink>
            ) : (
              <Text italic color="secondary">
                unknown
              </Text>
            )}
          </td>
        </tr>
        {issue.firstSeen && (
          <tr>
            <th>First Detected in Project</th>
            <td>{formatDate(new Date(issue.firstSeen))}</td>
          </tr>
        )}
        <tr>
          <th>CVE Published</th>
          <td className={cx({ [styles.secondary]: publishedDate === UNKNOWN })}>{publishedDate}</td>
        </tr>
        <tr>
          <th>CVE Last Modified</th>
          <td className={cx({ [styles.secondary]: publishedDate === UNKNOWN })}>{lastModifiedDate}</td>
        </tr>
        <tr>
          <th>Scoring Authority</th>
          <td>{issue.cve.scoringAuthority}</td>
        </tr>
        <tr>
          <th>Scanner</th>
          <td>{capitalizeFirstLetter(issue.tool.name)}</td>
        </tr>
      </tbody>
    </table>
  );
};

const getStyles = (theme: GrafanaTheme2) => ({
  table: css({
    width: '100%',
    borderCollapse: 'collapse',
    border: `1px solid ${theme.colors.border.strong}`,
    background: theme.colors.background.canvas,
    'th, td': {
      padding: '8px',
      borderBottom: `1px solid ${theme.colors.border.strong}`,
    },
    th: {
      borderRight: `1px solid ${theme.colors.border.strong}`,
    },
  }),
  secondary: css({
    color: theme.colors.text.secondary,
    fontStyle: 'italic',
  }),
  severity: (severity: string) => {
    switch (severity.toUpperCase()) {
      case 'CRITICAL':
        return css({ color: theme.visualization.getColorByName('dark-red') });
      case 'HIGH':
        return css({ color: theme.visualization.getColorByName('dark-orange') });
      case 'MEDIUM':
        return css({ color: theme.visualization.getColorByName('dark-yellow') });
      case 'LOW':
        return css({ color: theme.visualization.getColorByName('grey') });
      default:
        return css({ color: theme.colors.text.primary });
    }
  },
});
