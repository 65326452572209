import { PanelBuilders, SceneDataTransformer } from '@grafana/scenes';
import { FieldColorModeId, MappingType, TableCellDisplayMode } from '@grafana/schema';
import { CustomCellRendererProps } from '@grafana/ui';
import { ActionsCell } from 'components/IssuesTable';
import { CVES_ROUTE } from 'shared/constants/routes/appRoutes';
import { MISSING_SLO_MAPPING, SLO_REMAINING_THRESHOLDS, TOOL_MAPPINGS } from 'shared/panelConstants';

export const getRelatedIssuesTable = (cve: string, sourceID: string, versionID: string) =>
  PanelBuilders.table()
    .setData(
      new SceneDataTransformer({
        transformations: [
          {
            id: 'filterByValue',
            options: {
              filters: [
                {
                  fieldName: 'cve',
                  config: {
                    id: 'equal',
                    options: {
                      value: cve,
                    },
                  },
                },
              ],
              type: 'include',
              match: 'any',
            },
          },
          {
            id: 'organize',
            options: {
              excludeByName: {
                cve: true,
                cveId: true,
                cvssScore: true,
                severity: true,
              },
              indexByName: {
                package: 1,
                installedVersion: 2,
                fixedVersion: 3,
                target: 4,
                sloRemaining: 6,
                toolName: 7,
                id: 8,
              },
              renameByName: {
                id: 'Details',
                package: 'Package',
                installedVersion: 'Installed',
                fixedVersion: 'Fixed In',
                target: 'Target',
                sloRemaining: 'SLO Remaining',
                toolName: 'Detected By',
              },
            },
          },
          {
            id: 'sortBy',
            options: {
              sort: [
                {
                  field: 'SLO Remaining',
                },
              ],
            },
          },
        ],
      })
    )
    .setTitle(`Packages vulnerable to ${cve}`)
    .setThresholds(SLO_REMAINING_THRESHOLDS)
    .setOverrides((overridesBuilder) => {
      overridesBuilder
        .matchFieldsWithName('Package')
        .overrideLinks([
          {
            title: 'See ${__data.fields.package}',
            url: `${CVES_ROUTE}?var-package=\${__data.fields.package}`,
          },
        ])
        .overrideCustomFieldConfig('filterable', true);

      overridesBuilder
        .matchFieldsWithName('Installed')
        .overrideCustomFieldConfig('align', 'center')
        .overrideCustomFieldConfig('cellOptions', {
          type: TableCellDisplayMode.ColorText,
        })
        .overrideColor({
          mode: FieldColorModeId.Fixed,
          fixedColor: 'red',
        });

      overridesBuilder
        .matchFieldsWithName('Fixed In')
        .overrideCustomFieldConfig('align', 'center')
        .overrideCustomFieldConfig('cellOptions', {
          type: TableCellDisplayMode.ColorText,
        })
        .overrideColor({
          mode: FieldColorModeId.Fixed,
          fixedColor: 'green',
        });

      overridesBuilder
        .matchFieldsWithName('Target')
        .overrideCustomFieldConfig('cellOptions', {
          type: TableCellDisplayMode.ColorText,
        })
        .overrideColor({
          mode: FieldColorModeId.Fixed,
          fixedColor: 'text',
        })
        .overrideMappings([
          {
            type: MappingType.ValueToText,
            options: {
              '': {
                text: 'No Target',
                index: 0,
                color: 'grey',
              },
            },
          },
        ]);

      overridesBuilder
        .matchFieldsWithName('toolName')
        .overrideCustomFieldConfig('align', 'center')
        .overrideMappings(TOOL_MAPPINGS);

      overridesBuilder
        .matchFieldsWithName('SLO Remaining')
        .overrideCustomFieldConfig('cellOptions', {
          type: TableCellDisplayMode.ColorText,
        })
        .overrideCustomFieldConfig('align', 'center')
        .overrideUnit('days')
        .overrideMappings(MISSING_SLO_MAPPING);

      overridesBuilder
        .matchFieldsWithName('Details')
        .overrideCustomFieldConfig('align', 'center')
        .overrideCustomFieldConfig('cellOptions', {
          // @ts-ignore
          type: 'custom',
          cellComponent: (props: CustomCellRendererProps) => ActionsCell(props, sourceID, versionID),
        })
        .overrideCustomFieldConfig('width', 70);
    })
    .build();
