import { EmbeddedScene, SceneAppPage, SceneFlexLayout, SceneReactObject } from '@grafana/scenes';
import { PlanRelease } from 'components/PlanRelease';
import { PLAN_RELEASE_ROUTE } from 'shared/constants/routes/appRoutes';

export const getPlanReleaseTab = () =>
  new SceneAppPage({
    title: 'Plan a Security Release',
    titleIcon: 'rocket',
    subTitle: 'Determine which packages need be updated before releasing a new version',
    key: 'planReleasePage',
    url: PLAN_RELEASE_ROUTE,
    getScene: () => {
      return new EmbeddedScene({
        key: 'planRelease',
        body: new SceneFlexLayout({
          direction: 'column',
          children: [
            new SceneReactObject({
              component: PlanRelease,
            }),
          ],
        }),
      });
    },
  });
