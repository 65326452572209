import { useQuery } from '@apollo/client';
import { Alert } from '@grafana/ui';
import { GetSloStatusQuery } from '__generated__/graphql';

import { GET_SLO_STATUS } from './StaleSloBannerQueries';

interface StaleSloBannerType {
  versionID: string;
}

export const StaleSloBanner = ({ versionID }: StaleSloBannerType) => {
  const { data, loading, error } = useQuery<GetSloStatusQuery>(GET_SLO_STATUS, {
    variables: {
      id: versionID,
    },
  });

  if (loading || error) {
    return null;
  }

  return !data?.version.trackSlos ? (
    <Alert title="SLOs are Stale" severity="warning">
      A patch is available. Please track vulnerability SLOs in the updated version.
    </Alert>
  ) : null;
};
