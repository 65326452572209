import { useMutation } from '@apollo/client';
import { AppEvents } from '@grafana/data';
import { getAppEvents } from '@grafana/runtime';
import { CollapsableSection, Button, LoadingPlaceholder, ConfirmModal } from '@grafana/ui';
import { CreateContainerSourceConfigInput, CreateContainerSourceMutation } from '__generated__/graphql';
import { Suspense, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { REPOSITORIES } from 'shared/constants/sourceRepositories';

import { ConfigureAlerting } from './ConfigureAlerting';
import { ConfigureContainerScans } from './ConfigureContainerScans';
import { ConfigureGroups } from './ConfigureGroups';
import { CREATE_CONTAINER_SOURCE } from './ConfigureSourceMutations';
import type {
  ContainerScanningConfig,
  GroupConfig,
  SlackAlertConfig,
  GithubAlertConfig,
  SharedScanningConfig,
} from './types';

export interface ContainerSourceFormType
  extends ContainerScanningConfig,
    SharedScanningConfig,
    GroupConfig,
    SlackAlertConfig,
    GithubAlertConfig {}

export const ConfigureContainer = () => {
  const [isScanOpen, setIsScanOpen] = useState<boolean>(true);
  const [isGroupOpen, setIsGroupOpen] = useState<boolean>(true);
  const [isAlertOpen, setIsAlertOpen] = useState<boolean>(true);
  const [isConfirmOpen, setIsConfirmOpen] = useState<boolean>(false);
  const [formData, setFormData] = useState<ContainerSourceFormType | null>(null);

  const { control, handleSubmit, watch, formState, reset } = useForm<ContainerSourceFormType>({
    defaultValues: {
      imageName: '',
      repository: '',
      autoArchive: false,
      allowedFlavors: [],
      blocklistedFlavors: [],
      groups: [],
      slackAlertsEnabled: false,
      slackChannelName: '',
      slackSeverities: [],
      githubIssuesEnabled: false,
      githubCreateSubIssues: false,
      githubRepositoryName: '',
      githubLabels: [],
      githubAssignees: [],
      majorReleases: 0,
      minorReleases: 0,
      patchReleases: 0,
      lastTagsToScan: 0,
      useSemverPattern: false,
    },
  });

  const [createContainerSource] = useMutation<CreateContainerSourceMutation>(CREATE_CONTAINER_SOURCE, {
    ignoreResults: true,
    onError: () => {
      getAppEvents().publish({
        type: AppEvents.alertError.name,
        payload: [`Error: failed to create source`],
      });
    },
    onCompleted: () => {
      getAppEvents().publish({
        type: AppEvents.alertSuccess.name,
        payload: [`Success: created source`],
      });
      setFormData(null);
      reset();
    },
  });

  const slackAlertsEnabled = watch('slackAlertsEnabled');
  const githubIssuesEnabled = watch('githubIssuesEnabled');
  const repository = watch('repository');
  const useSemverPattern = watch('useSemverPattern');

  const onSubmit: SubmitHandler<ContainerSourceFormType> = (data) => {
    setFormData(data);
    setIsConfirmOpen(true);
  };

  const handleConfirmSubmit = () => {
    if (formData) {
      const payload: CreateContainerSourceConfigInput = {
        imageName: formData.imageName,
        repository: formData.repository,
        autoArchive: formData.autoArchive,
        majorReleases: formData.majorReleases,
        minorReleases: formData.minorReleases,
        patchReleases: formData.patchReleases,
        lastTagsToScan: formData.lastTagsToScan,
        useLastNTags: formData.repository !== REPOSITORIES.DOCKER_HUB && !formData.useSemverPattern,
        allowedFlavors: formData.allowedFlavors,
        blocklistedFlavors: formData.blocklistedFlavors,
        alertingConfig: {
          githubAssignees: formData.githubAssignees,
          githubCreateSubIssues: formData.githubCreateSubIssues,
          githubIssuesEnabled: formData.githubIssuesEnabled,
          githubLabels: formData.githubLabels,
          githubRepositoryName: formData.githubRepositoryName,
          slackAlertsEnabled: formData.slackAlertsEnabled,
          slackChannelName: formData.slackChannelName,
          slackSeverities: formData.slackSeverities.map((val) => val.value!),
        },
        groups: formData.groups.map((group) => group.name),
      };

      createContainerSource({
        variables: {
          input: payload,
        },
      });

      setIsConfirmOpen(false);
    }
  };

  const handleCancelSubmit = () => {
    setIsConfirmOpen(false);
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <CollapsableSection label="2. Configure Scans" isOpen={isScanOpen} onToggle={setIsScanOpen}>
          <ConfigureContainerScans
            control={control}
            formState={formState}
            repository={repository}
            useSemverPattern={useSemverPattern}
          />
        </CollapsableSection>
        <CollapsableSection label="3. Configure Groups" isOpen={isGroupOpen} onToggle={setIsGroupOpen}>
          <Suspense fallback={<LoadingPlaceholder text="Loading..." />}>
            <ConfigureGroups control={control} />
          </Suspense>
        </CollapsableSection>
        <CollapsableSection label="4. Configure Alerts" isOpen={isAlertOpen} onToggle={setIsAlertOpen}>
          <ConfigureAlerting
            control={control}
            formState={formState}
            slackAlertsEnabled={slackAlertsEnabled}
            githubIssuesEnabled={githubIssuesEnabled}
          />
        </CollapsableSection>
        <Button type="submit" size="md">
          Create Container Source
        </Button>
      </form>
      <ConfirmModal
        isOpen={isConfirmOpen}
        title={`Confirm Source Creation`}
        body={`Are you sure you want to create source ${formData?.imageName}?`}
        confirmText="Create"
        confirmButtonVariant="success"
        onConfirm={handleConfirmSubmit}
        onDismiss={handleCancelSubmit}
      />
    </>
  );
};
