import { useSuspenseQuery } from '@apollo/client';
import { css, cx } from '@emotion/css';
import { Stack, Text, useStyles2, useTheme2, Icon } from '@grafana/ui';
import { GetSourceSummariesWithSloQuery, SourceWithSlo } from '__generated__/graphql';
import { memo, useMemo, useState, useCallback } from 'react';

import { BaseTable } from '../base';

import { GET_SOURCES_SUMMARIES_WITH_SLO } from './SourceOverviewTableQueries';
import { SourceOverviewTableRow } from './SourceOverviewTableRow';

enum Direction {
  Asc = 'asc',
  Desc = 'desc',
}

interface SourceOverviewTableType {
  group: string;
  latestOnly: string;
  onlyIssuesWithSlo: string;
  excludeK8sSources: string;
}

const SourceOverviewTable = memo(
  ({ group, latestOnly, onlyIssuesWithSlo, excludeK8sSources }: SourceOverviewTableType) => {
    const styles = useStyles2(getStyles);
    const theme = useTheme2();

    const { data } = useSuspenseQuery<GetSourceSummariesWithSloQuery>(GET_SOURCES_SUMMARIES_WITH_SLO, {
      variables: {
        filters: {
          groupId: group,
          latestOnly,
          onlyIssuesWithSlo,
          fromConfigScanOnly: excludeK8sSources,
        },
      },
      fetchPolicy: 'cache-and-network',
    });

    const sourceSummaries = useMemo(() => data?.summary?.sourcesWithSlo || [], [data]);

    const [sortConfig, setSortConfig] = useState<{
      key: keyof SourceWithSlo['totalCveCounts'] | 'lowestSloRemaining' | 'totalSecrets';
      direction: Direction.Asc | Direction.Desc;
    } | null>({
      // Sort by SLO compliance by default
      key: 'lowestSloRemaining',
      direction: Direction.Asc,
    });

    const sortedSummaries = useMemo(() => {
      if (!sortConfig || !sourceSummaries.length) {
        return sourceSummaries;
      }

      const sorted = [...sourceSummaries].sort((a, b) => {
        const aValue =
          sortConfig.key === 'lowestSloRemaining' || sortConfig.key === 'totalSecrets'
            ? a[sortConfig.key]
            : a.totalCveCounts[sortConfig.key];
        const bValue =
          sortConfig.key === 'lowestSloRemaining' || sortConfig.key === 'totalSecrets'
            ? b[sortConfig.key]
            : b.totalCveCounts[sortConfig.key];

        if (typeof aValue === 'number' && typeof bValue === 'number') {
          return aValue - bValue;
        }

        return 0;
      });

      return sortConfig.direction === Direction.Asc ? sorted : sorted.reverse();
    }, [sourceSummaries, sortConfig]);

    const handleSort = useCallback(
      (key: keyof SourceWithSlo['totalCveCounts'] | 'lowestSloRemaining' | 'totalSecrets') => {
        setSortConfig((current) => {
          if (current?.key === key) {
            return { key, direction: current.direction === Direction.Asc ? Direction.Desc : Direction.Asc };
          }
          return { key, direction: Direction.Desc };
        });
      },
      []
    );

    const renderSortIcon = (key: keyof SourceWithSlo['totalCveCounts'] | 'lowestSloRemaining' | 'totalSecrets') => {
      if (sortConfig?.key !== key) {
        return null;
      }

      const isLowestSlo = key === 'lowestSloRemaining';
      const isAscending = sortConfig.direction === Direction.Asc;
      const showArrowUp = isLowestSlo ? !isAscending : isAscending;

      return showArrowUp ? <Icon name="arrow-up" /> : <Icon name="arrow-down" />;
    };

    return (
      <Stack direction="column">
        {sortedSummaries.length ? (
          <>
            <BaseTable theme={theme}>
              <thead>
                <tr>
                  <th className={styles.center}>Type</th>
                  <th>Name</th>
                  <th>Groups</th>
                  <th className={styles.sortable} onClick={() => handleSort('totalSecrets')}>
                    Exposed Secrets {renderSortIcon('totalSecrets')}
                  </th>
                  <th className={styles.sortable} onClick={() => handleSort('critical')}>
                    Critical {renderSortIcon('critical')}
                  </th>
                  <th className={styles.sortable} onClick={() => handleSort('high')}>
                    High {renderSortIcon('high')}
                  </th>
                  <th className={styles.sortable} onClick={() => handleSort('medium')}>
                    Medium {renderSortIcon('medium')}
                  </th>
                  <th className={styles.sortable} onClick={() => handleSort('low')}>
                    Low {renderSortIcon('low')}
                  </th>
                  <th
                    className={cx(styles.sortable, styles.sloComplianceColumn)}
                    onClick={() => handleSort('lowestSloRemaining')}
                  >
                    SLO Compliance {renderSortIcon('lowestSloRemaining')}
                  </th>
                </tr>
              </thead>
              <tbody>
                {sortedSummaries.map((sourceSummary, index) => (
                  <SourceOverviewTableRow
                    sourceSummary={sourceSummary as SourceWithSlo}
                    key={`${sourceSummary.source.id}-${index}`}
                  />
                ))}
              </tbody>
            </BaseTable>
          </>
        ) : (
          <Text italic color="secondary">
            No Sources
          </Text>
        )}
      </Stack>
    );
  }
);

SourceOverviewTable.displayName = 'SourceOverviewTable';

export { SourceOverviewTable };

const getStyles = () => ({
  center: css({
    textAlign: 'center',
  }),
  sortable: css({
    textAlign: 'center',
    cursor: 'pointer',
    userSelect: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  }),
  sloComplianceColumn: css({
    minWidth: '164px',
  }),
});
