import { EmbeddedScene, SceneAppPage, SceneAppPageLike, SceneFlexLayout, SceneReactObject } from '@grafana/scenes';
import { BugBountyReportControls } from 'components/BugBountyReport/BugBountyFullReportButton';
import { BugBountyReportContainer } from 'components/BugBountyReport/BugBountyReportContainer';
import { BugBountyReportHeader } from 'components/BugBountyReport/BugBountyReportHeader';
import { BUG_BOUNTY_REPORT_ROUTE } from 'shared/constants/routes/appRoutes';

interface GetBugBountyReportDrilldownType {
  submissionCode: string;
  parent: SceneAppPageLike;
}

export const getBugBountyReportDrilldown = ({ submissionCode, parent }: GetBugBountyReportDrilldownType) =>
  new SceneAppPage({
    title: 'Bug Bounty Report',
    renderTitle: () => BugBountyReportHeader({ submissionCode }),
    key: 'bugBountyReportDrilldown',
    url: `${BUG_BOUNTY_REPORT_ROUTE}/${submissionCode}`,
    controls: [
      new SceneReactObject({
        component: () => BugBountyReportControls({ submissionCode }),
      }),
    ],
    getParentPage: () => parent,
    getScene: () => {
      return new EmbeddedScene({
        key: 'bugBountyReport',
        body: new SceneFlexLayout({
          direction: 'column',
          children: [
            new SceneFlexLayout({
              direction: 'column',
              height: '100%',
              children: [
                new SceneReactObject({
                  component: () => BugBountyReportContainer({ submissionCode }),
                }),
              ],
            }),
          ],
        }),
      });
    },
  });
