import { useQuery } from '@apollo/client';
import { Icon, Stack, Text, useTheme2 } from '@grafana/ui';
import { GetExceptionHeaderQuery } from '__generated__/graphql';

import { GET_EXCEPTION_HEADER } from './ExceptionDetailsHeaderQueries';

interface ExceptionDetailsHeaderType {
  exceptionID: string;
}

export const ExceptionDetailsHeader = ({ exceptionID }: ExceptionDetailsHeaderType) => {
  const theme = useTheme2();

  const { data, loading, error } = useQuery<GetExceptionHeaderQuery>(GET_EXCEPTION_HEADER, {
    variables: {
      id: exceptionID,
    },
  });

  if (loading || error) {
    return null;
  }

  const { cve, reason } = data!.cveException;

  return (
    <Stack direction="row" alignItems="center">
      <Icon name="exclamation-triangle" width="32" height="32" color={theme.colors.warning.main} />
      <Text variant="h1">
        Exception: {cve.cve} <Text color="secondary">({reason})</Text>
      </Text>
    </Stack>
  );
};
