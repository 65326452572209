import { TypedDocumentNode, gql } from '@apollo/client';
import {
  GetCveIssueCountsQuery,
  GetCveIssueCountsQueryVariables,
  GetCveListQuery,
  GetCveListQueryVariables,
  GetImpactedVersionListQuery,
  GetImpactedVersionListQueryVariables,
} from '__generated__/graphql';

export const GET_CVE_LIST: TypedDocumentNode<GetCveListQuery, GetCveListQueryVariables> = gql`
  query getCveList($filters: CveFilters, $first: Int, $after: Int) {
    cves(filters: $filters, first: $first, after: $after) {
      response {
        id
        cve
        title
      }
    }
  }
`;

export const GET_IMPACTED_VERSIONS_LIST: TypedDocumentNode<
  GetImpactedVersionListQuery,
  GetImpactedVersionListQueryVariables
> = gql`
  query getImpactedVersionList($id: ID!) {
    cve(id: $id) {
      id
      issues {
        version {
          id
          version
          isArchived
          source {
            id
            name
            type
          }
        }
      }
    }
  }
`;

export const GET_CVE_ISSUE_COUNTS: TypedDocumentNode<GetCveIssueCountsQuery, GetCveIssueCountsQueryVariables> = gql`
  query getCveIssueCounts($id: ID!) {
    cve(id: $id) {
      id
      cve
      issues {
        version {
          id
          sourceId
        }
      }
    }
  }
`;
