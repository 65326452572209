import {
  SceneObjectState,
  SceneObjectBase,
  SceneComponentProps,
  sceneGraph,
  VariableDependencyConfig,
} from '@grafana/scenes';
import { VersionSummaryContainer } from 'components/VersionSummary';

interface VersionsState extends SceneObjectState {
  sourceID: string;
  tag: string;
}

export class Versions extends SceneObjectBase<VersionsState> {
  static Component = VersionsRenderer;

  protected _variableDependency = new VariableDependencyConfig(this, {
    statePaths: ['tag'],
  });
}

function VersionsRenderer({ model }: SceneComponentProps<Versions>) {
  const { sourceID, tag } = model.useState();

  return <VersionSummaryContainer sourceID={sourceID} tag={sceneGraph.interpolate(model, tag)} />;
}
