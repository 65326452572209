import { SceneQueryRunner } from '@grafana/scenes';
import { createInfinityQuery, getInfinityDatasource } from 'datasources/infinity';

export const getCveCountsPerClusterQueryRunner = (apiUrl: string) =>
  new SceneQueryRunner({
    datasource: getInfinityDatasource(),
    queries: [
      createInfinityQuery({
        refId: 'A',
        apiUrl,
        queryBody: `
          query getCveCountsPerCluster($filters: K8sClusterFilters) {
            k8sClusters(filters: $filters) {
              response {
                id
                name
                totalCveCounts {
                  critical
                  high
                  medium
                  low
                }
              }
            }
          }
        `,
        variables: {
          filters: {
            name: '${name}',
            tankaTeam: '${tankaTeam}',
            githubTeam: '${githubTeam}',
          },
        },
        overrides: {
          base: {
            root_selector: 'data.k8sClusters.response',
            columns: [
              {
                selector: 'id',
                text: 'id',
                type: 'string',
              },
              {
                selector: 'name',
                text: 'Cluster',
                type: 'string',
              },
              {
                selector: 'totalCveCounts.critical',
                text: 'Critical',
                type: 'number',
              },
              {
                selector: 'totalCveCounts.high',
                text: 'High',
                type: 'number',
              },
              {
                selector: 'totalCveCounts.medium',
                text: 'Medium',
                type: 'number',
              },
              {
                selector: 'totalCveCounts.low',
                text: 'Low',
                type: 'number',
              },
            ],
          },
        },
      }),
    ],
  });
