import { css, cx } from '@emotion/css';
import { GrafanaTheme2 } from '@grafana/data';
import { Box, useStyles2 } from '@grafana/ui';
import { useMemo } from 'react';

import { hexToRgba } from '../utils';

interface SloComplianceBoxType {
  value: number;
}

export const SloComplianceBox = ({ value }: SloComplianceBoxType) => {
  const styles = useStyles2(getStyles);

  const { background, label } = useMemo(() => {
    if (value <= 0) {
      return { background: styles.outOfSlo, label: 'Out of SLO' };
    } else if (value <= 7) {
      return { background: styles.approachingSlo, label: 'Approaching SLO' };
    } else {
      return { background: styles.insideSlo, label: 'Inside SLO' };
    }
  }, [value, styles]);

  return (
    <div className={cx(styles.box, background)}>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        paddingX={1}
        paddingY={0.25}
        borderRadius="default"
      >
        {label}
      </Box>
    </div>
  );
};

const getStyles = (theme: GrafanaTheme2) => ({
  box: css({
    color: theme.colors.text.maxContrast,
  }),
  outOfSlo: css({
    border: `1px solid ${theme.visualization.getColorByName('red')}`,
    background: hexToRgba(theme.visualization.getColorByName('dark-red'), 0.1),
  }),
  approachingSlo: css({
    border: `1px solid ${theme.visualization.getColorByName('dark-orange')}`,
    background: hexToRgba(theme.visualization.getColorByName('dark-orange'), 0.1),
  }),
  insideSlo: css({
    border: `1px solid ${theme.visualization.getColorByName('dark-green')}`,
    background: hexToRgba(theme.visualization.getColorByName('dark-green'), 0.1),
  }),
});
