import { AppEvents } from '@grafana/data';
import { getAppEvents } from '@grafana/runtime';
import { BugBountyDetailedSubmission, getSubmission } from 'api/getSubmission';
import { useEffect, useState } from 'react';
import { ErrorResponse } from 'shared/types';

export const useFetchBugBountySubmission = (submissionCode: string) => {
  const [submission, setSubmission] = useState<BugBountyDetailedSubmission | ErrorResponse>();
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    setLoading(true);
    getSubmission(submissionCode)
      .then(({ data }) => setSubmission(data))
      .catch(() =>
        getAppEvents().publish({
          type: AppEvents.alertError.name,
          payload: [`Error: failed to fetch bug bounty report`],
        })
      )
      .finally(() => setLoading(false));
  }, [submissionCode]);

  return {
    submission,
    loading,
  };
};
