import { PanelBuilders } from '@grafana/scenes';
import { BigValueColorMode } from '@grafana/schema';
import { SECRET_TOTAL_THRESHOLDS } from 'shared/panelConstants';

export const getSecretTotalsStat = () =>
  PanelBuilders.stat()
    .setTitle(`Exposed Secrets`)
    .setDisplayMode('transparent')
    .setOption('colorMode', BigValueColorMode.Background)
    .setOverrides((overridesBuilder) => {
      overridesBuilder.matchFieldsWithName('totalSecretCount').overrideThresholds(SECRET_TOTAL_THRESHOLDS);
    })
    .build();
