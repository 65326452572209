import { FetchResponse } from '@grafana/runtime';
import { INTIGRITI_URL } from 'shared/constants/routes/proxiedRoutes';
import { ErrorResponse } from 'shared/types';

import { get } from './baseApi';

type BugBountyReport = Partial<{
  originalTitle: string;
  type: {
    name: string;
    category: string;
    cwe: string;
  };
  domain: {
    name: string;
    motivation: string;
    type: {
      id: number;
      value: string;
    };
    tier: {
      id: number;
      value: string;
    };
    description: string;
  };
  endpointVulnerableComponent: string;
  pocDescription: string;
  impact: string;
  personalData: boolean;
  recommendedSolution: string;
  attachments: Array<{
    url: string;
    code: number;
  }>;
  ip: string;
}>;

export type BugBountyDetailedSubmission = {
  report?: BugBountyReport;
};

export const getSubmission = async (
  submissionCode: string
): Promise<FetchResponse<BugBountyDetailedSubmission | ErrorResponse>> => {
  return await get<BugBountyDetailedSubmission>(`${INTIGRITI_URL}/submissions/${submissionCode}`);
};
