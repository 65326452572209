import { useSuspenseQuery } from '@apollo/client';
import { css } from '@emotion/css';
import { Text, Stack, useStyles2, EmptySearchResult, Divider } from '@grafana/ui';
import type { License, GetLicensesQuery } from '__generated__/graphql';
import { useState } from 'react';

import { CategoryBox } from './LicenseConfidenceBox';
import { LicenseList } from './LicenseList';
import { LicensePreviewContainer } from './LicensePreviewContainer';
import { GET_LICENSES } from './LicenseQueries';

interface LicenseGridType {
  versionId: string;
  pkg: string;
  first: string;
  after: string;
  category: string;
}

export const LicenseGrid = ({ versionId, pkg, first, after, category }: LicenseGridType) => {
  const styles = useStyles2(getStyles);
  const [activeTarget, setTarget] = useState<string>();
  const [activePkg, setPkg] = useState<string>();
  const [activeLicense, setLicense] = useState<License>();

  const { data } = useSuspenseQuery<GetLicensesQuery>(GET_LICENSES, {
    variables: {
      filters: {
        ...(versionId.length && { versionId }),
        ...(pkg.length && { pkg }),
        ...(category.length && { category }),
      },
      ...(first.length && { first }),
      ...(after.length && { after }),
    },
    fetchPolicy: 'cache-and-network',
  });

  const licenses = data.licenses.response as License[];
  const groupObj: Record<string, Record<string, License[]>> = {};

  const validTarget = (t: string | undefined): t is string => !!t && !!groupObj[t];
  const validPkg = (p: string | undefined): p is string =>
    validTarget(activeTarget) && !!p && !!groupObj[activeTarget][p];

  licenses.forEach((license) => {
    const { target, package: Package, filePath } = license;
    const pkg = Package || filePath;

    if (!groupObj[target]) {
      groupObj[target] = {
        [pkg]: [license],
      };
    } else if (!groupObj[target][pkg]) {
      groupObj[target][pkg] = [license];
    } else {
      groupObj[target][pkg].push(license);
    }
  });

  if (!Object.keys(groupObj).length) {
    return (
      <div className={`${styles.gridContainer} ${styles.noResults}`}>
        <EmptySearchResult>No license violations were found.</EmptySearchResult>
      </div>
    );
  }

  return (
    <div className={styles.gridContainer}>
      <Divider direction="horizontal" spacing={0} />
      <Stack direction="row">
        <Divider direction="vertical" spacing={0} />
        <LicenseList<string>
          items={Object.keys(groupObj)}
          getItemName={(item) => item}
          equals={(item, activeItem) => item === activeItem}
          activeItem={activeTarget}
          setItem={setTarget}
        />
        <Divider direction="vertical" spacing={0} />
        <LicenseList<string>
          items={(validTarget(activeTarget) && Object.keys(groupObj[activeTarget])) || []}
          getItemName={(item) => item}
          equals={(item, activeItem) => item === activeItem}
          activeItem={activePkg}
          setItem={setPkg}
        />
        <Divider direction="vertical" spacing={0} />
        <LicenseList<License>
          items={(validPkg(activePkg) && groupObj[activeTarget!][activePkg]) || []}
          getItemName={(item) => item.name}
          equals={(item, activeItem) => item.id === activeItem?.id}
          activeItem={activeLicense}
          setItem={setLicense}
          figure={(license) => <CategoryBox category={license.category} />}
          meta={(license) => <Text>{`${Math.round(license.confidence * 100)}% Confidence Score`}</Text>}
        />
        <Divider direction="vertical" spacing={0} />
        <LicensePreviewContainer
          activeTarget={activeTarget}
          activePkg={(validPkg(activePkg) && activePkg) || undefined}
          activeLicense={activeLicense}
        />
        <Divider direction="vertical" spacing={0} />
      </Stack>
      <Divider direction="horizontal" spacing={0} />
    </div>
  );
};

const getStyles = () => ({
  noResults: css({
    gridTemplateColumns: 'repeat(auto-fit, minmax(680px, 1fr))',
  }),
  gridContainer: css({
    display: 'grid',
  }),
});
