import { SceneAppPage, SceneAppPageLike } from '@grafana/scenes';
import { K8sClusterHeader } from 'components/K8sClusterHeader';
import { K8S_SCANNING_ROUTE } from 'shared/constants/routes/appRoutes';

import { getNamespacesTab } from './getNamespacesTab';
import { getVersionsTab } from './getVersionsTab';

interface GetK8sClusterDrilldownPageType {
  apiUrl: string;
  clusterID: string;
  parent: SceneAppPageLike;
}

export const getK8sClusterDrilldown = ({ apiUrl, clusterID, parent }: GetK8sClusterDrilldownPageType) =>
  new SceneAppPage({
    title: 'Cluster',
    renderTitle: () => K8sClusterHeader({ clusterID }),
    key: 'k8sClusterDrilldown',
    url: `${K8S_SCANNING_ROUTE}/${clusterID}`,
    getParentPage: () => parent,
    tabs: [getNamespacesTab({ apiUrl, clusterID }), getVersionsTab({ apiUrl, clusterID })],
  });
