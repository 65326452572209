import { useLazyQuery, useQuery } from '@apollo/client';
import { css, cx } from '@emotion/css';
import { AppEvents, GrafanaTheme2, SelectableValue } from '@grafana/data';
import { getAppEvents } from '@grafana/runtime';
import {
  Button,
  Checkbox,
  Field,
  Icon,
  IconButton,
  Input,
  Label,
  MultiSelect,
  RadioButtonGroup,
  ReactMonacoEditor,
  Select,
  Stack,
  Text,
  Tooltip,
  useStyles2,
} from '@grafana/ui';
import { GetCveListQuery, GetImpactedVersionListQuery, Issue } from '__generated__/graphql';
import { ChangeEvent, useEffect, useState } from 'react';
import { Controller, SubmitHandler, useForm, useFieldArray, useWatch } from 'react-hook-form';
import { CVE_LS_KEY } from 'shared/constants/localStorage';

import { capitalizeFirstLetter } from '../utils';

import { GET_CVE_LIST, GET_IMPACTED_VERSIONS_LIST } from './CreateExceptionQueries';
import { ExceptionConfirmationModal } from './ExceptionConfirmationModal';
import { parseSourceOptionsFromIssues, parseVersionOptionsFromIssues } from './utils';

export enum EXCEPTION_REASONS {
  NotExploitable = 'Not Exploitable',
  FalsePositive = 'False Positive',
  WontFixDeprecated = `Won't Fix (Deprecated)`,
}

export type TargetsType = Array<{
  source: SelectableValue<number> | null;
  versions: Array<SelectableValue<number>>;
  all: boolean;
}>;

export interface ExceptionFormType {
  cve: SelectableValue<number> | null;
  targets: TargetsType;
  category: string;
  team: string;
  description: string;
}

export const CreateException = () => {
  const styles = useStyles2(getStyles);

  const [showConfirmation, setShowConfirmation] = useState<boolean>(true);
  const [exceptionFormData, setExceptionFormData] = useState<ExceptionFormType | null>(null);

  // Check if there's a CVE in local storage
  let defaultCveData = null;
  const cveData = localStorage.getItem(CVE_LS_KEY);
  if (cveData) {
    const { cve: defaultCve, id: defaultCveID } = JSON.parse(cveData);
    defaultCveData = { label: defaultCve, value: defaultCveID };
  }

  const defaultTargetsData = [{ source: null, versions: [], all: false }];

  const {
    control,
    handleSubmit,
    reset,
    resetField,
    setValue,
    formState: { errors },
  } = useForm<ExceptionFormType>({
    defaultValues: {
      cve: defaultCveData,
      targets: defaultTargetsData,
      category: '',
      team: '',
      description: '',
    },
  });

  const cveValue = useWatch({
    control,
    name: 'cve',
    defaultValue: defaultCveData,
  });
  const targetValue = useWatch({
    control,
    name: 'targets',
    defaultValue: defaultTargetsData,
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'targets',
  });

  const { data: cveList, loading: loadingCveList } = useQuery<GetCveListQuery>(GET_CVE_LIST, {
    onError: () => {
      getAppEvents().publish({
        type: AppEvents.alertError.name,
        payload: [`Error: failed to fetch CVEs`],
      });
    },
  });

  const [getImpactedVersionList, { loading: loadingImpactedList, data: impactedList }] =
    useLazyQuery<GetImpactedVersionListQuery>(GET_IMPACTED_VERSIONS_LIST, {
      fetchPolicy: 'cache-and-network',
      onError: () => {
        getAppEvents().publish({
          type: AppEvents.alertError.name,
          payload: [`Error: failed to fetch impacted sources and versions`],
        });
      },
    });

  useEffect(() => {
    if (cveValue) {
      resetField('targets');
      getImpactedVersionList({
        variables: {
          id: cveValue.value,
        },
      });
    }
  }, [cveValue, getImpactedVersionList, resetField]);

  const onSubmit: SubmitHandler<ExceptionFormType> = (formData) => {
    setExceptionFormData(formData);
    setShowConfirmation(true);
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
        <Field label="CVE">
          <Controller
            name="cve"
            control={control}
            rules={{ required: true }}
            render={({ field: { ref, ...field } }) => (
              <Select
                {...field}
                allowCustomValue={false}
                placeholder="Select a CVE"
                options={
                  cveList
                    ? (cveList.cves.response.map((cve) => ({
                        label: cve.cve,
                        value: cve.id,
                        description: capitalizeFirstLetter(cve.title),
                      })) as SelectableValue[])
                    : []
                }
                isLoading={loadingCveList}
                width={30}
                invalid={!!errors.cve}
              />
            )}
          />
        </Field>
        <Field label="Reason for the Exception">
          <Controller
            name="category"
            control={control}
            rules={{ required: true }}
            render={({ field: { ref, ...field } }) => (
              <RadioButtonGroup
                {...field}
                size="md"
                options={Object.values(EXCEPTION_REASONS).map((reason) => ({
                  label: reason,
                  value: reason,
                }))}
                invalid={!!errors.category}
              />
            )}
          />
        </Field>
        {fields.map((formField, index) => {
          const sourceOptions = impactedList
            ? parseSourceOptionsFromIssues(impactedList.cve.issues as Issue[], targetValue)
            : [];

          const versionOptions =
            impactedList && targetValue[index]?.source?.value
              ? parseVersionOptionsFromIssues(impactedList.cve.issues as Issue[], targetValue[index]?.source?.value!)
              : [];

          return (
            <div key={formField.id} className={styles.target}>
              <Stack direction="row" justifyContent="space-between" alignItems="flex-start">
                <Stack direction="column">
                  <Field label="Source">
                    <Controller
                      name={`targets.${index}.source`}
                      control={control}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <Select
                          {...field}
                          virtualized
                          allowCustomValue={false}
                          placeholder="Select a source"
                          options={sourceOptions}
                          isLoading={loadingImpactedList}
                          width={60}
                          invalid={errors.targets && !!errors.targets[index]?.source}
                        />
                      )}
                    />
                  </Field>
                  <Field label="Versions">
                    <Controller
                      name={`targets.${index}.versions`}
                      control={control}
                      rules={{ required: !targetValue[index]?.all }}
                      render={({ field }) => (
                        <Stack direction="row" alignItems="center">
                          <MultiSelect
                            {...field}
                            virtualized
                            placeholder={targetValue[index]?.all ? 'All' : 'Select versions'}
                            options={versionOptions}
                            isLoading={loadingImpactedList}
                            width={60}
                            invalid={errors.targets && !!errors.targets[index]?.versions}
                            disabled={targetValue[index]?.all}
                          />
                          <Controller
                            name={`targets.${index}.all`}
                            control={control}
                            render={({ field }) => (
                              <Checkbox
                                {...field}
                                label="All Versions (includes future)"
                                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                  setValue(`targets.${index}.all`, e.target.checked);
                                  setValue(`targets.${index}.versions`, []);
                                }}
                                disabled={!versionOptions.length}
                              />
                            )}
                          />
                        </Stack>
                      )}
                    />
                  </Field>
                </Stack>
                {targetValue.length > 1 && (
                  <IconButton
                    name="trash-alt"
                    tooltip="Remove Target"
                    variant="destructive"
                    size="lg"
                    onClick={() => remove(index)}
                  />
                )}
              </Stack>
            </div>
          );
        })}
        <div className={styles.addSource}>
          <Button size="sm" icon="plus" onClick={() => append({ source: null, versions: [], all: false })}>
            Add Another Source
          </Button>
        </div>
        <Field
          label={
            <Label>
              <Stack alignItems="center">
                <span>Team</span>
                <Tooltip
                  interactive
                  content={
                    <>
                      <Text element="p">Which team performed the investigation?</Text>
                      <Text element="p">i.e. security-engineering</Text>
                    </>
                  }
                >
                  <Icon name="info-circle" size="sm" />
                </Tooltip>
              </Stack>
            </Label>
          }
        >
          <Controller
            name="team"
            control={control}
            rules={{ required: true }}
            render={({ field: { ref, ...field } }) => <Input {...field} width={40} invalid={!!errors.team} />}
          />
        </Field>
        <Field label="Description">
          <Controller
            name="description"
            control={control}
            rules={{ required: true }}
            render={({ field: { ref, ...field } }) => (
              <ReactMonacoEditor
                {...field}
                language="markdown"
                className={cx(styles.editor, { [styles.invalid]: !!errors.description })}
                options={{
                  overviewRulerLanes: 0,
                  wordWrap: 'on',
                  renderLineHighlight: 'none',
                  folding: false,
                  lineNumbers: 'off',
                  lineDecorationsWidth: 8,
                  fontSize: 14,
                  minimap: {
                    enabled: false,
                  },
                  scrollbar: {
                    vertical: 'hidden',
                  },
                }}
              />
            )}
          />
        </Field>
        <Button type="submit">Preview Exception</Button>
      </form>
      {exceptionFormData && (
        <ExceptionConfirmationModal
          formData={exceptionFormData}
          isOpen={Boolean(showConfirmation)}
          onDismiss={() => setShowConfirmation(false)}
          onConfirm={() => {
            reset();
            setShowConfirmation(false);
          }}
        />
      )}
    </>
  );
};

const getStyles = (theme: GrafanaTheme2) => ({
  form: css({
    maxWidth: '720px',
  }),
  target: css({
    borderTop: `1px solid ${theme.colors.border.medium}`,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  }),
  addSource: css({
    borderBottom: `1px solid ${theme.colors.border.medium}`,
    paddingBottom: theme.spacing(1),
    marginBottom: theme.spacing(3),
  }),
  editor: css({
    background: theme.colors.background.canvas,
    border: `1px solid ${theme.colors.border.medium}`,
    padding: '4px 0px',
    resize: 'vertical',
    overflow: 'auto',
    height: '160px',
  }),
  invalid: css({
    border: `1px solid ${theme.colors.error.border}`,
  }),
});
