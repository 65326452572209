import { SceneAppPage, SceneReactObject } from '@grafana/scenes';
import { LinkButton } from '@grafana/ui';
import { TitleWithIcon } from 'components/TitleWithIcon';
import { CVE_LS_KEY } from 'shared/constants/localStorage';
import { CVES_ROUTE, CVE_EXCEPTIONS_CREATE_ROUTE } from 'shared/constants/routes/appRoutes';

import { getExploreCveExceptionsTab } from './getExploreCveExceptionsTab';
import { getExploreCvesTab } from './getExploreCvesTab';

interface GetCvesPageType {
  apiUrl: string;
}

export const getCvesPage = ({ apiUrl }: GetCvesPageType) =>
  new SceneAppPage({
    title: 'CVEs',
    renderTitle(title) {
      return TitleWithIcon({ title, icon: 'bug', color: 'error' });
    },
    subTitle: 'Common Vulnerabilities and Exposures',
    key: 'cvesPage',
    url: CVES_ROUTE,
    controls: [
      new SceneReactObject({
        component: () => (
          <LinkButton
            variant="destructive"
            size="sm"
            href={CVE_EXCEPTIONS_CREATE_ROUTE}
            onClick={() => localStorage.removeItem(CVE_LS_KEY)}
          >
            Create CVE Exception
          </LinkButton>
        ),
      }),
    ],
    tabs: [getExploreCvesTab({ apiUrl }), getExploreCveExceptionsTab({ apiUrl })],
  });
