import { SceneQueryRunner } from '@grafana/scenes';
import { createInfinityQuery, getInfinityDatasource } from 'datasources/infinity';

export const getCvesWithSloQueryRunner = (apiUrl: string, versionID: string) =>
  new SceneQueryRunner({
    datasource: getInfinityDatasource(),
    queries: [
      createInfinityQuery({
        refId: 'A',
        apiUrl,
        queryBody: `
            query getCvesWithSlo($filters: IssueFilters, $first: Int, $after: Int) {
              issues(filters: $filters, first: $first, after: $after) {
                response {
                  id
                  package
                  target
                  installedVersion
                  fixedVersion
                  cve {
                    id
                    cve
                    cvssScore
                    severity
                  }
                  sloRemaining
                  tool {
                    name
                  }
                }
              }
            }
          `,
        variables: {
          filters: {
            versionId: versionID,
            cve: '${cve}',
            package: '${pkg}',
          },
        },
        overrides: {
          base: {
            root_selector: 'data.issues.response',
            columns: [
              {
                text: 'id',
                selector: 'id',
                type: 'number',
              },
              {
                text: 'cveId',
                selector: 'cve.id',
                type: 'number',
              },
              {
                text: 'cve',
                selector: 'cve.cve',
                type: 'string',
              },
              {
                text: 'cvssScore',
                selector: 'cve.cvssScore',
                type: 'number',
              },
              {
                text: 'severity',
                selector: 'cve.severity',
                type: 'string',
              },
              {
                text: 'sloRemaining',
                selector: 'sloRemaining',
                type: 'number',
              },
              {
                text: 'package',
                selector: 'package',
                type: 'string',
              },
              {
                text: 'target',
                selector: 'target',
                type: 'string',
              },
              {
                text: 'installedVersion',
                selector: 'installedVersion',
                type: 'string',
              },
              {
                text: 'fixedVersion',
                selector: 'fixedVersion',
                type: 'string',
              },
              {
                text: 'toolName',
                selector: 'tool.name',
                type: 'string',
              },
            ],
          },
        },
      }),
    ],
  });
