import { css } from '@emotion/css';
import { GrafanaTheme2 } from '@grafana/data';
import { useStyles2, Text, Box, Stack, Tooltip, Icon } from '@grafana/ui';
import { Slo } from '__generated__/graphql';
import { useMemo } from 'react';

import { capitalizeFirstLetter } from '../utils';

interface SloTableType {
  slo: Slo;
  severity: string;
}

export const SloTable = ({ slo, severity }: SloTableType) => {
  const styles = useStyles2(getStyles);

  const severityColor = useMemo(() => {
    const sev = severity.toUpperCase();

    let textColor = styles.none;
    if (sev === 'CRITICAL') {
      textColor = styles.critical;
    } else if (sev === 'HIGH') {
      textColor = styles.high;
    } else if (sev === 'MEDIUM') {
      textColor = styles.medium;
    } else if (sev === 'LOW') {
      textColor = styles.low;
    }
    return textColor;
  }, [severity, styles]);

  return (
    <Box backgroundColor="canvas" borderColor="strong" borderStyle="solid" marginTop={2}>
      <Box padding={1}>
        <Text element="h4" variant="h2">
          SLO Requirements for a <span className={severityColor}>{capitalizeFirstLetter(severity)}</span> Issue
        </Text>
      </Box>
      <table className={styles.table}>
        <tbody>
          <tr>
            <th>Time to Mitigate</th>
            <td>
              <Stack alignItems="center" justifyContent="space-between">
                {slo.timeToMitigate} days
                <Tooltip
                  placement="left"
                  content="The time to make a vulnerability non-exploitable. This does not necessarily mean patched."
                >
                  <Icon name="question-circle" size="sm" />
                </Tooltip>
              </Stack>
            </td>
          </tr>
          <tr>
            <th>Time to Release</th>
            <td>
              <Stack alignItems="center" justifyContent="space-between">
                {slo.timeToRelease} days
                <Tooltip placement="left" content="The time to release a patch">
                  <Icon name="question-circle" size="sm" />
                </Tooltip>
              </Stack>
            </td>
          </tr>
          <tr>
            <th>Incident</th>
            <td>{slo.incident}</td>
          </tr>
        </tbody>
      </table>
    </Box>
  );
};

const getStyles = (theme: GrafanaTheme2) => ({
  table: css({
    width: '100%',
    borderCollapse: 'collapse',
    'th, td': {
      padding: '8px',
      borderTop: `1px solid ${theme.colors.border.strong}`,
    },
    th: {
      borderRight: `1px solid ${theme.colors.border.strong}`,
    },
  }),
  critical: css({
    color: theme.visualization.getColorByName('dark-red'),
  }),
  high: css({
    color: theme.visualization.getColorByName('dark-orange'),
  }),
  medium: css({
    color: theme.visualization.getColorByName('dark-yellow'),
  }),
  low: css({
    color: theme.visualization.getColorByName('grey'),
  }),
  none: css({
    color: theme.colors.text.primary,
  }),
});
