import { BackendSrvRequest, getBackendSrv, FetchResponse } from '@grafana/runtime';
import { lastValueFrom } from 'rxjs';
import { ErrorResponse } from 'shared/types';

export async function fetchApi<D>(
  url: string,
  optionsOverride: Partial<BackendSrvRequest> = {}
): Promise<FetchResponse<D> | FetchResponse<ErrorResponse>> {
  const options: BackendSrvRequest = {
    url,
    ...optionsOverride,
  };

  // @ts-ignore
  return await lastValueFrom(getBackendSrv().fetch<D>(options));
}

export async function get<D>(url: string): Promise<FetchResponse<D> | FetchResponse<ErrorResponse>> {
  return fetchApi<D>(url, { method: 'GET' });
}

export async function post<D>(url: string, data: any): Promise<FetchResponse<D> | FetchResponse<ErrorResponse>> {
  return fetchApi<D>(url, { method: 'POST', data, headers: { 'Content-Type': 'application/json' } });
}

export async function put<D>(url: string, data: any): Promise<FetchResponse<D> | FetchResponse<ErrorResponse>> {
  return fetchApi<D>(url, { method: 'PUT', data, headers: { 'Content-Type': 'application/json' } });
}

export async function del<D>(url: string): Promise<FetchResponse<D> | FetchResponse<ErrorResponse>> {
  return fetchApi<D>(url, { method: 'DELETE' });
}

// Type guard to check if network request was successfull or an error
export const isErrorRes = (res: FetchResponse<any | ErrorResponse>): res is FetchResponse<ErrorResponse> => {
  return 'error' in res.data;
};
