import { PanelBuilders, SceneDataTransformer } from '@grafana/scenes';
import { BigValueColorMode, BigValueTextMode, VizOrientation } from '@grafana/schema';
import { BUG_BOUNTY_PAYOUT_THRESHOLDS } from 'shared/panelConstants';

export const getBugBountyPayoutsByTeamStat = () =>
  PanelBuilders.stat()
    .setData(
      new SceneDataTransformer({
        transformations: [
          {
            id: 'groupBy',
            options: {
              fields: {
                reward: {
                  aggregations: ['sum'],
                  operation: 'aggregate',
                },
                squad: {
                  operation: 'groupby',
                },
              },
            },
          },
          {
            id: 'sortBy',
            options: {
              sort: [
                {
                  field: 'reward (sum)',
                  desc: true,
                },
              ],
            },
          },
          {
            id: 'limit',
            options: {
              limitField: 5,
            },
          },
        ],
      })
    )
    .setTitle('Top Payouts by Squad')
    .setNoValue('No bug bounty submissions')
    .setUnit('currencyUSD')
    .setDisplayMode('transparent')
    .setOption('reduceOptions', {
      values: true,
      calcs: ['firstNotNull'],
    })
    .setOption('wideLayout', false)
    .setOption('colorMode', BigValueColorMode.Background)
    .setOption('textMode', BigValueTextMode.ValueAndName)
    .setOption('orientation', VizOrientation.Vertical)
    .setThresholds(BUG_BOUNTY_PAYOUT_THRESHOLDS)
    .setOverrides((overridesBuilder) => {
      overridesBuilder.matchFieldsWithName('squad').overrideNoValue('No Squad Assigned');
    })
    .build();
