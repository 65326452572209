import { EmbeddedScene, SceneAppPage, SceneAppPageLike, SceneFlexLayout, SceneReactObject } from '@grafana/scenes';
import { Text, TextLink } from '@grafana/ui';
import { CreateException } from 'components/CreateException';
import { CVE_EXCEPTIONS_CREATE_ROUTE } from 'shared/constants/routes/appRoutes';

interface GetCreateCveExceptionDrilldownType {
  parent: SceneAppPageLike;
}

export const getCreateCveExceptionDrilldown = ({ parent }: GetCreateCveExceptionDrilldownType) =>
  new SceneAppPage({
    title: 'Create Exception',
    titleIcon: 'edit',
    subTitle: (
      <Text>
        Dismiss a CVE by creating an exception. If you don&apos;t see an exception listed check our{' '}
        <TextLink external href="https://github.com/grafana/cve-exceptions-tracker/issues">
          legacy tracker
        </TextLink>
      </Text>
    ),
    key: 'createException',
    url: CVE_EXCEPTIONS_CREATE_ROUTE,
    getParentPage: () => parent,
    getScene: () => {
      return new EmbeddedScene({
        key: 'createExceptionDrilldown',
        body: new SceneFlexLayout({
          direction: 'column',
          maxWidth: '100%',
          children: [
            new SceneReactObject({
              component: CreateException,
            }),
          ],
        }),
      });
    },
  });
