import {
  EmbeddedScene,
  SceneAppPage,
  SceneFlexItem,
  SceneFlexLayout,
  SceneVariableSet,
  VariableValueSelectors,
} from '@grafana/scenes';
import { VariableHide } from '@grafana/schema';
import { Icon } from '@grafana/ui';
import { TextInputVariable } from 'controls/TextInputVariable';
import { Versions } from 'customScenes/Versions';
import { SOURCES_ROUTE } from 'shared/constants/routes/appRoutes';

import { getVersionDrilldown } from './getVersionDrilldown';

interface GetVersionsTabType {
  apiUrl: string;
  sourceID: string;
}

export const getVersionsTab = ({ apiUrl, sourceID }: GetVersionsTabType) =>
  new SceneAppPage({
    title: 'Versions',
    titleIcon: 'folder',
    key: 'versionsTab',
    url: `${SOURCES_ROUTE}/${sourceID}`,
    getScene: () =>
      new EmbeddedScene({
        key: 'versions',
        $variables: new SceneVariableSet({
          variables: [
            new TextInputVariable({
              name: 'tag',
              prefix: <Icon name="search" />,
              placeholder: 'Search versions',
              width: 40,
              hide: VariableHide.hideLabel,
            }),
          ],
        }),
        body: new SceneFlexLayout({
          direction: 'column',
          children: [
            new SceneFlexItem({
              height: '100%',
              body: new Versions({
                sourceID,
                tag: '${tag}',
              }),
            }),
          ],
        }),
        controls: [new VariableValueSelectors({})],
      }),
    drilldowns: [
      {
        routePath: `${SOURCES_ROUTE}/:sourceID/version/:versionID`,
        getPage(routeMatch, parent) {
          const sourceID = routeMatch.params.sourceID;
          const versionID = routeMatch.params.versionID;
          return getVersionDrilldown({ apiUrl, sourceID, versionID, parent });
        },
      },
    ],
  });
