import {
  CustomVariable,
  EmbeddedScene,
  SceneAppPage,
  SceneFlexItem,
  SceneFlexLayout,
  SceneVariableSet,
  VariableValueSelectors,
} from '@grafana/scenes';
import { VariableHide } from '@grafana/schema';
import { Icon } from '@grafana/ui';
import { LicenseCategory } from '__generated__/graphql';
import { TextInputVariable } from 'controls/TextInputVariable';
import { Licenses } from 'customScenes/Licenses';
import { SOURCES_ROUTE } from 'shared/constants/routes/appRoutes';

interface GetLicensesTabType {
  sourceID: string;
  versionID: string;
}

export const getLicensesTab = ({ sourceID, versionID }: GetLicensesTabType) => {
  const packageVariable = new TextInputVariable({
    name: 'pkg',
    prefix: <Icon name="search" />,
    placeholder: 'Search Packages',
    width: 40,
    hide: VariableHide.hideLabel,
  });

  const categoryVariable = new CustomVariable({
    name: 'category',
    label: 'Category',
    value: LicenseCategory.All,
    text: LicenseCategory.All,
    query: `All : ${LicenseCategory.All},Forbidden : ${LicenseCategory.Forbidden},Restricted : ${LicenseCategory.Restricted},Reciprocal : ${LicenseCategory.Reciprocal},Notice : ${LicenseCategory.Notice},Permissive : ${LicenseCategory.Permissive},Unencumbered : ${LicenseCategory.Unencumbered},Unknown : ${LicenseCategory.Unknown}`,
  });

  return new SceneAppPage({
    title: 'Licenses',
    titleIcon: 'book-open',
    url: `${SOURCES_ROUTE}/${sourceID}/version/${versionID}/licenses`,
    getScene: () =>
      new EmbeddedScene({
        key: 'license',
        $variables: new SceneVariableSet({
          variables: [packageVariable, categoryVariable],
        }),
        body: new SceneFlexLayout({
          width: '100%',
          direction: 'column',
          children: [
            new SceneFlexItem({
              body: new Licenses({
                versionId: versionID,
                pkg: '${pkg}',
                category: '${category}',
                licenseFirst: '',
                licenseAfter: '',
              }),
            }),
          ],
        }),
        controls: [new VariableValueSelectors({})],
      }),
  });
};
