import { CustomCellRendererProps } from '@grafana/ui';
import { getSourceImage } from 'components/utils';
import { memo } from 'react';
import { REPOSITORIES } from 'shared/constants/sourceRepositories';

const OriginCell = memo((props: CustomCellRendererProps) => {
  const { text } = props.field.display!(props.value);

  const sourceImage = getSourceImage(text);

  return (
    <img src={sourceImage} alt={text === REPOSITORIES.GITHUB ? 'Repository' : 'Container'} height="20px" width="20px" />
  );
});

OriginCell.displayName = 'OriginCell';

export { OriginCell };
