import { SceneQueryRunner } from '@grafana/scenes';
import { createInfinityQuery, getInfinityDatasource } from 'datasources/infinity';

export const getWeeklyCvesPatchedQueryRunner = (apiUrl: string) =>
  new SceneQueryRunner({
    datasource: getInfinityDatasource(),
    queries: [
      createInfinityQuery({
        refId: 'A',
        apiUrl,
        queryBody: `
          query getSummary($filters: SummaryFilters) {
            summary(filters: $filters) {
              weeklyPatchedCveCount {
                week
                severity
                patchedCveCount
              }
            }
          }
        `,
        variables: {
          filters: {
            groupId: '$group',
            latestOnly: '$latestOnly',
            onlyIssuesWithSlo: '$onlyIssuesWithSlo',
            fromConfigScanOnly: '$excludeK8sSources',
          },
        },
        overrides: {
          base: {
            root_selector: 'data.summary.weeklyPatchedCveCount',
            columns: [
              {
                selector: 'patchedCveCount',
                text: 'patchedCveCount',
                type: 'number',
              },
              {
                selector: 'severity',
                text: 'severity',
                type: 'string',
              },
              {
                selector: 'week',
                text: 'week',
                type: 'timestamp',
              },
            ],
            filterExpression: `severity != 'UNKNOWN'`,
          },
        },
      }),
    ],
  });
