import { css } from '@emotion/css';
import { GrafanaTheme2 } from '@grafana/data';
import { Box, Stack, useStyles2, Text, Tag, IconButton } from '@grafana/ui';
import { CveException } from '__generated__/graphql';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom-v5-compat';
import { SOURCES_ROUTE } from 'shared/constants/routes/appRoutes';

import { AddVersionModal } from './AddVersionModal';

interface ExceptionDetailsMetadataType {
  exception: CveException;
}

export const ExceptionDetailsMetadata = ({ exception }: ExceptionDetailsMetadataType) => {
  const styles = useStyles2(getStyles);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const navigate = useNavigate();

  return (
    <>
      <Box backgroundColor="canvas" borderColor="strong" borderStyle="solid" padding={1.5}>
        <Stack direction="column">
          <Stack direction="row" justifyContent="space-between">
            <Text variant="h3">Impacted Versions</Text>
            <IconButton name="edit" tooltip="Add versions" variant="secondary" onClick={() => setIsModalOpen(true)} />
          </Stack>
          <div className={styles.tagList}>
            {exception.exceptionVersions.map(({ version }, index) => {
              const name = version?.source?.name;
              const tag = version?.version;
              const icon = version?.source?.type === 'repository' ? 'github' : 'docker';
              return (
                <Tag
                  className={styles.tags}
                  name={`${name}:${tag}`}
                  icon={icon}
                  key={`tag-${exception.id}-${index}`}
                  onClick={() => navigate(`${SOURCES_ROUTE}/${version?.source!.id}/version/${version!.id}`)}
                />
              );
            })}
            {exception.exceptionSources.map(({ source }, index) => {
              const name = source?.name;
              const icon = source?.type === 'repository' ? 'github' : 'docker';
              return (
                <Tag
                  className={styles.tags}
                  name={`${name}:all`}
                  icon={icon}
                  key={`tag-${exception.id}-${index}`}
                  onClick={() => navigate(`${SOURCES_ROUTE}/${source!.id}`)}
                />
              );
            })}
          </div>
        </Stack>
      </Box>
      <AddVersionModal isOpen={isModalOpen} onDismiss={() => setIsModalOpen(false)} exception={exception} />
    </>
  );
};

const getStyles = (theme: GrafanaTheme2) => ({
  tags: css({
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
    svg: {
      marginRight: theme.spacing(0.5),
    },
  }),
  tagList: css({
    display: 'flex',
    flexWrap: 'wrap',
  }),
});
