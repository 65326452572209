import { SceneQueryRunner } from '@grafana/scenes';
import { createInfinityQuery, getInfinityDatasource } from 'datasources/infinity';

export const getBugBountySubmissionsQueryRunner = (apiUrl: string) =>
  new SceneQueryRunner({
    datasource: getInfinityDatasource(),
    queries: [
      createInfinityQuery({
        refId: 'A',
        apiUrl,
        queryBody: `
          query getBugBountySubmissions($filters: BugBountySubmissionFilters, $first: Int, $after: Int) {
            bugBountySubmissions(filters: $filters, first: $first, after: $after) {
              response {
                squad
                reward
              }
            }
          }
        `,
        variables: {
          filters: {
            submissionCode: '$submissionCode',
            squad: '$squad',
            status: '$status',
            severity: '$severity',
            from: '${__from:date:iso}',
            to: '${__to:date:iso}',
          },
        },
        overrides: {
          base: {
            root_selector: 'data.bugBountySubmissions.response',
          },
        },
      }),
    ],
  });
