import {
  SceneObjectState,
  SceneObjectBase,
  SceneComponentProps,
  sceneGraph,
  VariableDependencyConfig,
} from '@grafana/scenes';
import { CveCardGridContainer } from 'components/CveCard';

interface CvesState extends SceneObjectState {
  cve: string;
  package: string;
  sortBy: string;
  cveFirst: string;
  cveAfter: string;
}

export class Cves extends SceneObjectBase<CvesState> {
  static Component = CvesRenderer;

  protected _variableDependency = new VariableDependencyConfig(this, {
    statePaths: ['cve', 'package', 'sortBy', 'cveFirst', 'cveAfter'],
  });
}

function CvesRenderer({ model }: SceneComponentProps<Cves>) {
  const { cve, package: pkg, sortBy, cveFirst, cveAfter } = model.useState();

  return (
    <CveCardGridContainer
      cve={sceneGraph.interpolate(model, cve)}
      package={sceneGraph.interpolate(model, pkg)}
      sortBy={sceneGraph.interpolate(model, sortBy)}
      first={sceneGraph.interpolate(model, cveFirst)}
      after={sceneGraph.interpolate(model, cveAfter)}
    />
  );
}
