import deepmerge from 'deepmerge';

import type { InfinityURLOptions, InfinityQueryType, InfinityQueryWithDataSource, BackendParserOptions } from './types';

const mergeOptions = <T>(defaults: T, overrides?: Partial<T>): T => {
  return deepmerge(defaults, overrides || {});
};

const defaultOptions = {
  base: {
    type: 'graphql' as InfinityQueryType,
    source: 'url',
    format: 'dataframe',
    parser: 'backend',
  },
  url: {
    body_content_type: 'application/json',
    body_type: 'graphql',
    method: 'POST',
  } as InfinityURLOptions,
};

interface CreateInfinityQueryParams {
  refId: string;
  apiUrl: string;
  queryBody: string;
  variables?: Record<string, any>;
  overrides?: {
    base?: Partial<InfinityQueryWithDataSource<'graphql'>> & Pick<BackendParserOptions, 'filterExpression'>;
    url?: Partial<InfinityURLOptions>;
  };
}

const buildInfinityOptions = ({ refId, apiUrl, queryBody, variables, overrides = {} }: CreateInfinityQueryParams) => {
  const baseOpts = mergeOptions(defaultOptions.base, overrides.base);
  const urlOpts = mergeOptions(defaultOptions.url, {
    ...overrides.url,
    body_graphql_query: queryBody,
    body_graphql_variables: JSON.stringify(variables || {}),
  });

  return {
    refId,
    baseOpts,
    url: apiUrl,
    url_options: urlOpts,
  };
};

export const createInfinityQuery = (params: CreateInfinityQueryParams) => {
  const { refId, baseOpts, url, url_options } = buildInfinityOptions(params);

  return {
    refId,
    ...baseOpts,
    url,
    url_options,
    queryType: 'infinity',
  };
};

export const createInfinityVariable = (params: CreateInfinityQueryParams) => {
  const { refId, baseOpts, url, url_options } = buildInfinityOptions(params);

  return {
    refId,
    infinityQuery: {
      ...baseOpts,
      url,
      url_options,
    },
    queryType: 'infinity',
  };
};
