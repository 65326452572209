import pluginJson from '../../../plugin.json';

export const PLUGIN_ID = pluginJson.id;

// Base Routes
export const PLUGIN_BASE_URL = `/a/${PLUGIN_ID}`;
export const MENU_ROUTE = PLUGIN_BASE_URL;

// Projects Routes
export const PROJECTS_ROUTE = `${PLUGIN_BASE_URL}/projects`;
export const OVERVIEW_ROUTE = `${PROJECTS_ROUTE}/overview`;
export const SOURCES_ROUTE = `${PROJECTS_ROUTE}/sources`;
export const PLAN_RELEASE_ROUTE = `${PROJECTS_ROUTE}/plan-release`;
export const CONFIGURE_SOURCE_ROUTE = `${PROJECTS_ROUTE}/configure-source`;

// Kubernetes Routes
export const K8S_SCANNING_ROUTE = `${PLUGIN_BASE_URL}/k8s`;

// CVE Routes
export const CVES_ROUTE = `${PLUGIN_BASE_URL}/cves`;
export const CVE_DETAILS_ROUTE = `${CVES_ROUTE}/details`;
export const CVE_EXCEPTIONS_ROUTE = `${CVES_ROUTE}/exceptions`;
export const CVE_EXCEPTIONS_CREATE_ROUTE = `${CVE_EXCEPTIONS_ROUTE}/create`;
export const CVE_EXCEPTION_DETAILS_ROUTE = `${CVE_EXCEPTIONS_ROUTE}/details`;

// Bug Bounty Routes
export const BUG_BOUNTY_ROUTE = `${PLUGIN_BASE_URL}/bug-bounty`;
export const BUG_BOUNTY_REPORT_ROUTE = `${BUG_BOUNTY_ROUTE}/report`;

// Settings Routes
export const SETTINGS_ROUTE = `${PLUGIN_BASE_URL}/settings`;

// Other
export const SECRETS_ROUTE = `${PLUGIN_BASE_URL}/secrets`;
