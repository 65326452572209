import { SceneAppPage } from '@grafana/scenes';
import { SETTINGS_ROUTE } from 'shared/constants/routes/appRoutes';

import { getAccessTab } from './getAccessTab';

interface GetSettingsPageType {
  apiUrl: string;
}

export const getSettingsPage = ({ apiUrl }: GetSettingsPageType) =>
  new SceneAppPage({
    title: 'Settings',
    key: 'settingsPage',
    url: SETTINGS_ROUTE,
    tabs: [getAccessTab({ apiUrl })],
  });
