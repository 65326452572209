import { css, cx } from '@emotion/css';
import { GrafanaTheme2 } from '@grafana/data';
import { Box, TagList, Text, TextLink, Tooltip, useStyles2 } from '@grafana/ui';
import { Group, SourceWithSlo } from '__generated__/graphql';
import { getSourceImage } from 'components/utils';
import { memo } from 'react';
import { SOURCES_ROUTE } from 'shared/constants/routes/appRoutes';
import { GITHUB_PREFIX } from 'shared/constants/routes/externalRoutes';
import { REPOSITORIES } from 'shared/constants/sourceRepositories';

import { SloComplianceBox } from './SloComplianceBox';

const SourceOverviewTableRow = memo(({ sourceSummary }: { sourceSummary: SourceWithSlo }) => {
  const styles = useStyles2(getStyles);

  const { source, totalCveCounts, totalSecrets, lowestSloRemaining } = sourceSummary;

  const sourceName = source.name.replace(GITHUB_PREFIX, '');
  const sourceImage = getSourceImage(source.origin);
  const sourceImageTitle = source.origin === REPOSITORIES.GITHUB ? 'Respository' : 'Container';
  const sourceGroups = source.groups.map((group: Group) => group.name);

  return (
    <tr>
      <td className={styles.center}>
        <img src={sourceImage} title={sourceImageTitle} alt="Source Logo" height="20px" width="20px" />
      </td>
      <td>
        <TextLink href={`${SOURCES_ROUTE}/${source.id}`} inline={false}>
          {sourceName}
        </TextLink>
      </td>
      <td>
        {sourceGroups.length > 0 ? (
          <Tooltip
            content={
              <Box padding={1}>
                <TagList tags={sourceGroups} />
              </Box>
            }
          >
            <TagList tags={sourceGroups} displayMax={3} className={styles.tagList} />
          </Tooltip>
        ) : (
          <Text color="secondary" italic>
            No Groups
          </Text>
        )}
      </td>
      <td className={cx(styles.center, styles.critical, { [styles.none]: totalSecrets === 0 })}>
        <Text variant="h5">{totalSecrets}</Text>
      </td>
      <td className={cx(styles.center, styles.critical, { [styles.none]: totalCveCounts.critical === 0 })}>
        <Text variant="h5">{totalCveCounts.critical}</Text>
      </td>
      <td className={cx(styles.center, styles.high, { [styles.none]: totalCveCounts.high === 0 })}>
        <Text variant="h5">{totalCveCounts.high}</Text>
      </td>
      <td className={cx(styles.center, styles.medium, { [styles.none]: totalCveCounts.medium === 0 })}>
        <Text variant="h5">{totalCveCounts.medium}</Text>
      </td>
      <td className={cx(styles.center, styles.low, { [styles.none]: totalCveCounts.low === 0 })}>
        <Text variant="h5">{totalCveCounts.low}</Text>
      </td>
      <td className={styles.center}>
        <SloComplianceBox value={lowestSloRemaining} />
      </td>
    </tr>
  );
});

SourceOverviewTableRow.displayName = 'SourceOverviewTableRow';

export { SourceOverviewTableRow };

const getStyles = (theme: GrafanaTheme2) => ({
  center: css({
    textAlign: 'center',
  }),
  tagList: css({
    width: 'max-content',
    justifyContent: 'flex-start',
  }),
  critical: css({
    color: theme.visualization.getColorByName('dark-red'),
  }),
  high: css({
    color: theme.visualization.getColorByName('dark-orange'),
  }),
  medium: css({
    color: theme.visualization.getColorByName('dark-yellow'),
  }),
  low: css({
    color: theme.visualization.getColorByName('text'),
  }),
  none: css({
    color: theme.visualization.getColorByName('grey'),
  }),
});
