import { css } from '@emotion/css';
import { GrafanaTheme2 } from '@grafana/data';
import { Card, Icon, useStyles2, useTheme2 } from '@grafana/ui';
import {
  BUG_BOUNTY_ROUTE,
  CONFIGURE_SOURCE_ROUTE,
  CVES_ROUTE,
  K8S_SCANNING_ROUTE,
  PROJECTS_ROUTE,
} from 'shared/constants/routes/appRoutes';
import { ENGHUB_DOCS_URL } from 'shared/constants/routes/externalRoutes';
import { GRAFANA_LOGO_PNG, INTIGRITI_LOGO_SVG, K8S_LOGO_SVG } from 'shared/constants/routes/imageRoutes';

export const AppMenu = () => {
  const styles = useStyles2(getStyles);
  const theme = useTheme2();

  return (
    <div className={styles.gridContainer}>
      <Card href={PROJECTS_ROUTE}>
        <Card.Heading>Projects</Card.Heading>
        <Card.Description>Explore scanned internal projects</Card.Description>
        <Card.Figure>
          <img src={GRAFANA_LOGO_PNG} alt="Grafana Logo" width="40" height="40" />
        </Card.Figure>
      </Card>
      <Card href={K8S_SCANNING_ROUTE}>
        <Card.Heading>Kubernetes</Card.Heading>
        <Card.Description>Scanned K8s clusters</Card.Description>
        <Card.Figure>
          <img src={K8S_LOGO_SVG} alt="Kubernetes Logo" width="40" height="40" />
        </Card.Figure>
      </Card>
      <Card href={CVES_ROUTE}>
        <Card.Heading>CVEs</Card.Heading>
        <Card.Description>Explore Vulnerabilities</Card.Description>
        <Card.Figure>
          <Icon name="bug" width="40" height="40" color={theme.colors.error.main} />
        </Card.Figure>
      </Card>
      <Card href={CONFIGURE_SOURCE_ROUTE}>
        <Card.Heading>Configure a New Source</Card.Heading>
        <Card.Description>Add a project to get scanned daily</Card.Description>
        <Card.Figure>
          <Icon name="folder-plus" width="40" height="40" color={theme.colors.success.main} />
        </Card.Figure>
      </Card>
      <Card href={BUG_BOUNTY_ROUTE}>
        <Card.Heading>Bug Bounty</Card.Heading>
        <Card.Description>Reports powered by Intigriti</Card.Description>
        <Card.Figure>
          <img src={INTIGRITI_LOGO_SVG} alt="Intigriti Logo" width="40" height="40" />
        </Card.Figure>
      </Card>
      <Card href={ENGHUB_DOCS_URL}>
        <Card.Heading>Documentation</Card.Heading>
        <Card.Description>Usage and Technical Docs (Enghub)</Card.Description>
        <Card.Figure>
          <Icon name="book" width="40" height="40" color={theme.colors.info.main} />
        </Card.Figure>
      </Card>
    </div>
  );
};

const getStyles = (theme: GrafanaTheme2) => ({
  gridContainer: css({
    width: '100%',
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(380px, 1fr))',
    gap: theme.spacing(1),
  }),
});
