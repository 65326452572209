import { Alert } from '@grafana/ui';
import { Role } from '__generated__/graphql';
import { useRole } from 'hooks';

import { Users } from './Users';

export const AccessControl = () => {
  const { role } = useRole();

  return (
    <>
      {role === Role.Administrator ? (
        <Users />
      ) : (
        <Alert severity="info" title="Only administrators may access this page." />
      )}
    </>
  );
};
