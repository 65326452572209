import { TypedDocumentNode, gql } from '@apollo/client';
import {
  GetIssueReportQuery,
  GetIssueReportQueryVariables,
  GetRelatedIssuesQuery,
  GetRelatedIssuesQueryVariables,
} from '__generated__/graphql';

export const GET_ISSUE_REPORT: TypedDocumentNode<GetIssueReportQuery, GetIssueReportQueryVariables> = gql`
  query getIssueReport($id: ID!) {
    issue(id: $id) {
      id
      installedVersion
      fixedVersion
      vulnerableVersionRange
      package
      target
      versionId
      firstSeen
      firstSeenInVersion
      sloRemaining
      version {
        id
        sourceId
      }
      cve {
        id
        cve
        description
        url
        severity
        cvssScore
        cvssV3
        publishedDate
        lastModifiedDate
        scoringAuthority
      }
      tool {
        name
      }
      slo {
        id
        scoreHigh
        scoreLow
        timeToMitigate
        timeToRelease
        incident
      }
    }
  }
`;

export const GET_RELATED_ISSUES: TypedDocumentNode<GetRelatedIssuesQuery, GetRelatedIssuesQueryVariables> = gql`
  query getRelatedIssues($filters: IssueFilters!) {
    issues(filters: $filters) {
      response {
        id
        package
        target
        tool {
          name
        }
      }
    }
  }
`;
