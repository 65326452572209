import { SceneQueryRunner } from '@grafana/scenes';
import { createInfinityQuery, getInfinityDatasource } from 'datasources/infinity';

export const getCvesCountQueryRunner = (apiUrl: string) =>
  new SceneQueryRunner({
    datasource: getInfinityDatasource(),
    queries: [
      createInfinityQuery({
        refId: 'A',
        apiUrl,
        queryBody: `
          query getCves($filters: CveFilters, $first: Int, $after: Int) {
            cves(filters: $filters, first: $first, after: $after) {
              metadata {
                totalCount
              }
            }
          }
        `,
        variables: {
          filters: {
            cve: '$cve',
            package: '$package',
            sortBy: '$sortBy',
          },
          first: '$cveFirst',
          after: '$cveAfter',
        },
        overrides: {
          base: {
            root_selector: 'data.cves',
          },
        },
      }),
    ],
  });
