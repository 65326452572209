import { BusEventWithPayload } from '@grafana/data';
import { SECRET_SEVERITIES } from 'shared/constants/secrets';

interface BugBountySquadUpdatedEventPayload {
  squad: string;
}
export class BugBountySquadUpdatedEvent extends BusEventWithPayload<BugBountySquadUpdatedEventPayload> {
  public static readonly type = 'bug-bounty-squad-updated';
}

interface SourceCardGroupClickedEventPayload {
  groupName: string;
  groupID: string;
}
export class SourceCardGroupClickedEvent extends BusEventWithPayload<SourceCardGroupClickedEventPayload> {
  public static readonly type = 'source-card-group-clicked';
}

interface CveCardPackageClickedEventPayload {
  package: string;
}
export class CveCardPackageClickedEvent extends BusEventWithPayload<CveCardPackageClickedEventPayload> {
  public static readonly type = 'cve-card-package-clicked';
}

export type level = keyof typeof SECRET_SEVERITIES;

export const isLevel = (value: string): value is level => {
  return Object.keys(SECRET_SEVERITIES)
    .filter((severity) => severity)
    .includes(value as level);
};

interface SecretCardTagClickedEventPayload {
  severityValue?: level;
  severityLabel?: string;
  versionID?: string;
}

export class SecretCardTagClickedEvent extends BusEventWithPayload<SecretCardTagClickedEventPayload> {
  public static readonly type = 'secret-card-tag-clicked';
}
interface K8sCardNamespaceClickedEventPayload {
  id: string;
  name: string;
}
export class K8sCardNamespaceClickedEvent extends BusEventWithPayload<K8sCardNamespaceClickedEventPayload> {
  public static readonly type = 'k8s-card-namespace-clicked';
}
