import { useSuspenseQuery } from '@apollo/client';
import { css, cx } from '@emotion/css';
import { GrafanaTheme2 } from '@grafana/data';
import { Stack, Tag, TextLink, useStyles2 } from '@grafana/ui';
import { GetCveReportQuery } from '__generated__/graphql';
import { useNavigate } from 'react-router-dom-v5-compat';
import { CVES_ROUTE } from 'shared/constants/routes/appRoutes';

import { capitalizeFirstLetter, formatDate } from '../utils';

import { GET_CVE_REPORT } from './CveReportQueries';

const getNVDLink = (cvssV3: string) =>
  `https://nvd.nist.gov/vuln-metrics/cvss/v3-calculator?vector=${cvssV3.replace(/CVSS:3\.[01]\//, '')}&version=${
    cvssV3.startsWith('CVSS:3.1') ? '3.1' : '3.0'
  }`;

interface CveSummaryTableType {
  cve: string;
}

const UNKNOWN = 'unknown';

export const CveSummaryTable = ({ cve }: CveSummaryTableType) => {
  const styles = useStyles2(getStyles);
  const navigate = useNavigate();

  const { data } = useSuspenseQuery<GetCveReportQuery>(GET_CVE_REPORT, {
    variables: {
      filters: {
        cve,
      },
      first: 1,
    },
  });

  const cveData = data.cves.response[0];

  const publishedDate = cveData.publishedDate ? formatDate(new Date(cveData.publishedDate)) : UNKNOWN;
  const lastModifiedDate = cveData.lastModifiedDate ? formatDate(new Date(cveData.lastModifiedDate)) : UNKNOWN;
  const epssProbability = cveData.epssProbability ? cveData.epssProbability * 100 + '%' : UNKNOWN;

  const { packages, scanners } = cveData.issues.reduce(
    (acc, issue) => {
      if (!acc.packages.includes(issue.package)) {
        acc.packages.push(issue.package);
      }
      if (!acc.scanners.includes(issue.tool.name)) {
        acc.scanners.push(issue.tool.name);
      }
      return acc;
    },
    { packages: [] as string[], scanners: [] as string[] }
  );

  return (
    <table className={styles.table}>
      <tbody>
        <tr>
          <th>Package(s)</th>
          <td>
            <Stack direction="row" wrap="wrap">
              {packages.map((pkg, index) => (
                <Tag name={pkg} key={index} onClick={() => navigate(`${CVES_ROUTE}?var-package=${pkg}`)} />
              ))}
            </Stack>
          </td>
        </tr>
        <tr>
          <th>Severity</th>
          <td className={styles.severity(cveData.severity)}>{cveData.severity}</td>
        </tr>
        <tr>
          <th>CVSS</th>
          <td>{cveData.cvssScore}</td>
        </tr>
        <tr>
          <th>EPSS Probability</th>
          <td className={cx({ [styles.secondary]: epssProbability === UNKNOWN })}>{epssProbability}</td>
        </tr>
        <tr>
          <th>CVSS V3</th>
          <td>
            <TextLink href={cveData.cvssV3 && getNVDLink(cveData.cvssV3)} inline={false} external>
              {cveData.cvssV3}
            </TextLink>
          </td>
        </tr>
        <tr>
          <th>CVE Published</th>
          <td className={cx({ [styles.secondary]: publishedDate === UNKNOWN })}>{publishedDate}</td>
        </tr>
        <tr>
          <th>CVE Last Modified</th>
          <td className={cx({ [styles.secondary]: lastModifiedDate === UNKNOWN })}>{lastModifiedDate}</td>
        </tr>
        <tr>
          <th>Scoring Authority</th>
          <td>{cveData.scoringAuthority}</td>
        </tr>
        <tr>
          <th>Detected By</th>
          <td>{scanners.map((scanner) => capitalizeFirstLetter(scanner)).join(', ')}</td>
        </tr>
      </tbody>
    </table>
  );
};

const getStyles = (theme: GrafanaTheme2) => ({
  table: css({
    width: '100%',
    height: 'min-content',
    borderCollapse: 'collapse',
    border: `1px solid ${theme.colors.border.strong}`,
    background: theme.colors.background.canvas,
    'th, td': {
      padding: '8px',
      borderBottom: `1px solid ${theme.colors.border.strong}`,
    },
    th: {
      borderRight: `1px solid ${theme.colors.border.strong}`,
    },
  }),
  secondary: css({
    color: theme.colors.text.secondary,
    fontStyle: 'italic',
  }),
  severity: (severity: string) => {
    switch (severity.toUpperCase()) {
      case 'CRITICAL':
        return css({ color: theme.visualization.getColorByName('dark-red') });
      case 'HIGH':
        return css({ color: theme.visualization.getColorByName('dark-orange') });
      case 'MEDIUM':
        return css({ color: theme.visualization.getColorByName('dark-yellow') });
      case 'LOW':
        return css({ color: theme.visualization.getColorByName('grey') });
      default:
        return css({ color: theme.colors.text.primary });
    }
  },
});
