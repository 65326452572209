import { css } from '@emotion/css';
import { GrafanaTheme2 } from '@grafana/data';
import { usePluginInteractionReporter } from '@grafana/runtime';
import { Card, Text, useStyles2, AlertVariant, Alert, Stack, Icon, useTheme2 } from '@grafana/ui';
import { Version, Source, Secret } from '__generated__/graphql';
import { useNavigate } from 'react-router-dom-v5-compat';
import { SOURCES_ROUTE } from 'shared/constants/routes/appRoutes';
import { VERSIONS_CARD_CLICK } from 'shared/constants/trackingEvents';

import { formatDate, getSourceImage } from '../utils';

import { SeverityCounts } from './SeverityCounts';

interface VersionSummaryCardProps {
  version: Version;
  source: Source;
  secrets?: Secret[];
}

export interface CveCounts {
  critical: number;
  high: number;
  medium: number;
  low: number;
}

export const VersionSummaryCard = ({ version, source, secrets }: VersionSummaryCardProps) => {
  const styles = useStyles2(getStyles);
  const theme = useTheme2();
  const report = usePluginInteractionReporter();
  const navigate = useNavigate();
  const versionRoute = `${SOURCES_ROUTE}/${source.id}/version/${version.id}`;
  const sourceImage = getSourceImage(source.origin);

  const warnThreshold = 7;
  let alertProps: {
    title: string;
    severity: AlertVariant;
  };

  if (!version.trackSlos) {
    alertProps = {
      title: 'SLOs Stopped',
      severity: 'info',
    };
  } else if (version.lowestSloRemaining <= 0) {
    alertProps = {
      title: 'Out of SLO',
      severity: 'error',
    };
  } else if (version.lowestSloRemaining <= warnThreshold) {
    alertProps = {
      title: 'SLO running low',
      severity: 'warning',
    };
  } else {
    alertProps = {
      title: 'Inside SLOs',
      severity: 'success',
    };
  }

  return (
    <Card
      href={versionRoute}
      onClick={() =>
        report(VERSIONS_CARD_CLICK, {
          version_id: version.id,
        })
      }
    >
      <Card.Figure>
        <img src={sourceImage} alt="logo" height="40px" width="40px" className={styles.logo} />
      </Card.Figure>
      <Card.Heading>
        {source.name}:{version.version}
        {version.isMostRecentVersion && <Text color="secondary"> (latest)</Text>}
      </Card.Heading>
      <Card.Meta>
        {secrets && secrets.length > 0 && (
          <Stack alignItems="center" gap={0.5}>
            <Icon name="shield-exclamation" color={theme.colors.error.text} />
            <Text color="error">
              {secrets?.length} Exposed Secret{secrets?.length === 1 ? '' : 's'}
            </Text>
          </Stack>
        )}
        <Text>{`Last Scan: ${formatDate(new Date(version.latestScan))}`}</Text>
      </Card.Meta>
      <div className={styles.descriptionArea}>
        <Stack direction="column">
          <Stack direction="row" alignItems="center">
            <Text color="primary">CVEs&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</Text>
            <SeverityCounts counts={version.totalCveCounts} />
          </Stack>
          {version.trackSlos ? (
            <Stack direction="row" alignItems="center">
              <Text color="primary">Out of SLO</Text>
              <SeverityCounts counts={version.totalCveCountsOutOfSlo} isSlo />
            </Stack>
          ) : (
            <Stack direction="row" alignItems="center">
              <Text color="primary">Out of SLO</Text>
              <SeverityCounts counts={version.totalCveCountsOutOfSlo} isStale />
            </Stack>
          )}
        </Stack>
      </div>
      <Card.Tags>
        <Alert {...alertProps} bottomSpacing={0} onClick={() => navigate(versionRoute)} />
      </Card.Tags>
    </Card>
  );
};

const getStyles = (theme: GrafanaTheme2) => ({
  logo: css({
    objectFit: 'contain',
  }),
  descriptionArea: css({
    gridArea: 'Description',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  }),
});
