import { SceneQueryRunner } from '@grafana/scenes';
import { createInfinityQuery, getInfinityDatasource } from 'datasources/infinity';

export const getTotalSecretsCountsQueryRunner = (apiUrl: string, versionId?: string) =>
  new SceneQueryRunner({
    datasource: getInfinityDatasource(),
    queries: [
      createInfinityQuery({
        refId: 'A',
        apiUrl,
        queryBody: `
          query getSummary($filters: SummaryFilters) {
            summary(filters: $filters) {
              totalSecretCount
            }
          }
        `,
        variables: {
          filters: {
            groupId: '$group',
            latestOnly: '$latestOnly',
            onlyIssuesWithSlo: '$onlyIssuesWithSlo',
            fromConfigScanOnly: '$excludeK8sSources',
          },
        },
        overrides: {
          base: {
            root_selector: 'data.summary.totalSecretCount',
          },
        },
      }),
    ],
  });
