import { TypedDocumentNode, gql } from '@apollo/client';
import { GetSourceGroupsQuery, GetSourceGroupsQueryVariables } from '__generated__/graphql';

export const GET_SOURCE_GROUPS: TypedDocumentNode<GetSourceGroupsQuery, GetSourceGroupsQueryVariables> = gql`
  query getSourceGroups($id: ID!) {
    source(id: $id) {
      id
      groups {
        id
        name
      }
    }
  }
`;
