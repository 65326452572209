import { TypedDocumentNode, gql } from '@apollo/client';
import { GetSloStatusQuery, GetSloStatusQueryVariables } from '__generated__/graphql';

export const GET_SLO_STATUS: TypedDocumentNode<GetSloStatusQuery, GetSloStatusQueryVariables> = gql`
  query getSloStatus($id: ID!) {
    version(id: $id) {
      id
      trackSlos
    }
  }
`;
