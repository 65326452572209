import { PanelBuilders, SceneDataTransformer } from '@grafana/scenes';
import { TableCellDisplayMode } from '@grafana/schema';
import { OriginCell } from 'components/OriginCell';
import { VersionLinkCell } from 'components/VersionLinkCell';
import { VersionLinkCellType } from 'components/VersionLinkCell/VersionLinkCell';
import { SOURCES_ROUTE } from 'shared/constants/routes/appRoutes';
import { GITHUB_PREFIX } from 'shared/constants/routes/externalRoutes';
import { BOOL_MAPPINGS } from 'shared/panelConstants';
import { removeSubstringTransform } from 'transformations';

export const getImpactedVersionsTable = (cve: string) =>
  PanelBuilders.table()
    .setData(
      new SceneDataTransformer({
        transformations: [
          {
            id: 'groupBy',
            options: {
              fields: {
                name: {
                  operation: 'groupby',
                },
                origin: {
                  operation: 'groupby',
                },
                version: {
                  operation: 'groupby',
                },
                versionID: {
                  operation: 'groupby',
                },
                sourceID: {
                  operation: 'groupby',
                },
                isMostRecentVersion: {
                  operation: 'groupby',
                },
              },
            },
          },
          removeSubstringTransform({
            substring: GITHUB_PREFIX,
            fieldName: 'name',
          }),
          {
            id: 'organize',
            options: {
              indexByName: {
                origin: 0,
                name: 1,
                version: 2,
                isMostRecentVersion: 3,
              },
              renameByName: {
                version: 'Version',
                name: 'Source',
                origin: 'Type',
                isMostRecentVersion: 'Latest',
              },
            },
          },
        ],
      })
    )
    .setTitle('Impacted Versions')
    .setDisplayMode('transparent')
    .setNoValue('No impacted versions. Try updating the filters.')
    .setOverrides((overridesBuilder) => {
      overridesBuilder
        .matchFieldsWithName('Type')
        .overrideCustomFieldConfig('align', 'center')
        .overrideCustomFieldConfig('cellOptions', {
          // @ts-ignore
          type: 'custom',
          cellComponent: OriginCell,
        })
        .overrideCustomFieldConfig('width', 50);
      overridesBuilder
        .matchFieldsWithName('Source')
        .overrideCustomFieldConfig('filterable', true)
        .overrideLinks([
          {
            title: 'Source ${__data.fields.Source}',
            url: `${SOURCES_ROUTE}/\${__data.fields.sourceID}`,
          },
        ]);
      overridesBuilder
        .matchFieldsWithName('Version')
        .overrideCustomFieldConfig('filterable', true)
        .overrideCustomFieldConfig('cellOptions', {
          // @ts-ignore
          type: 'custom',
          cellComponent: (props: VersionLinkCellType) => <VersionLinkCell {...props} cve={cve} />,
        });

      overridesBuilder
        .matchFieldsWithName('Latest')
        .overrideCustomFieldConfig('filterable', true)
        .overrideCustomFieldConfig('align', 'center')
        .overrideCustomFieldConfig('cellOptions', {
          type: TableCellDisplayMode.ColorBackground,
        })
        .overrideMappings(BOOL_MAPPINGS)
        .overrideCustomFieldConfig('width', 120);
      overridesBuilder.matchFieldsWithName('sourceID').overrideCustomFieldConfig('hidden', true);
      overridesBuilder.matchFieldsWithName('versionID').overrideCustomFieldConfig('hidden', true);
      overridesBuilder.matchFieldsWithName('isArchived').overrideCustomFieldConfig('hidden', true);
    })
    .build();
