import React from 'react';
import { SceneComponentProps, sceneGraph, SceneObjectBase, SceneObjectState } from '@grafana/scenes';
import { DetectEvent, DetectEventTypes } from '../events';
import { CodeEditor, Modal } from '@grafana/ui';
import { LoadingState } from '@grafana/data';
import { ProcessingItem } from '../../shared/requests/conversions';
import { dump } from 'js-yaml';
import { render } from '../../components/scenes/logsource/queries/loki';

export interface LogSourceExportModalState extends SceneObjectState {}

interface LogSourceExportModalInternalState extends LogSourceExportModalState {
  open?: boolean;
}

export class LogSourceExportModal extends SceneObjectBase<LogSourceExportModalInternalState> {
  public static Component = Renderer;

  constructor(args: Partial<LogSourceExportModalState>) {
    super({
      ...args,
      open: false,
    });
    this.addActivationHandler(() => {
      this.events();
    });
  }

  private events() {
    this.getRoot().subscribeToEvent(DetectEvent, (evt) => {
      switch (evt.payload.type) {
        case DetectEventTypes.LogSourceExport:
          this.setState({
            open: true,
          });
          break;
        default:
          break;
      }
    });
  }

  public close() {
    this.setState({
      open: false,
    });
  }

  public renderYAML() {
    const data = sceneGraph.getData(this);

    if (!data || data.state.data?.state !== LoadingState.Done) {
      return '';
    }

    const d = data.state.data.series.at(0);
    if (!d) {
      return '';
    }

    const nameField = d.fields.find((f) => f.name === 'name');

    const field = d.fields.find((f) => f.name === 'config');
    if (!field) {
      return '';
    }

    let object = field.values.at(0) satisfies {
      extra: string;
      selector: string;
    };

    if (!object) {
      return '';
    }

    let transformations: Array<ProcessingItem> = [];
    transformations.push({
      id: 'custom_data_source',
      type: 'set_custom_attribute',
      attribute: 'logsource_loki_selection',
      value: render({ selector: object.selector, extra: '' }),
    });
    if (object.extra) {
      transformations.push({
        id: 'custom_parser',
        type: 'set_custom_attribute',
        attribute: 'loki_parser',
        value: object.extra,
      });
    }
    const name = toCamelCase((nameField?.values.at(0) ?? '') as string);
    const pipeline = {
      id: name,
      pipeline: {
        name: name,
        priority: 10,
        transformations,
      },
    };

    return dump(pipeline);
  }
}

function toCamelCase(input: string): string {
  return input
    .split(' ')
    .map((word) => word.toLowerCase())
    .map((word, index) => (index === 0 ? word : word[0].toUpperCase() + word.slice(1)))
    .join('');
}

function Renderer({ model }: SceneComponentProps<LogSourceExportModal>) {
  const { open } = model.useState();
  return (
    <Modal title={'Log Source as Pipelines'} isOpen={open} onDismiss={() => model.close()} closeOnBackdropClick={false}>
      <CodeEditor
        value={model.renderYAML()}
        language="yaml"
        height={300}
        monacoOptions={{
          readOnly: true,
          minimap: {
            enabled: false,
          },
        }}
      ></CodeEditor>
    </Modal>
  );
}
