import { useMutation, useSuspenseQuery } from '@apollo/client';
import { css } from '@emotion/css';
import { AppEvents, GrafanaTheme2 } from '@grafana/data';
import { getAppEvents } from '@grafana/runtime';
import { useStyles2, Select, ConfirmModal, Text, Box, Stack, Divider } from '@grafana/ui';
import { GetIssueCommentsQuery, RemoveCommentMutation } from '__generated__/graphql';
import { useState } from 'react';

import { CommentCard } from './CommentCard';
import { REMOVE_COMMENT } from './IssueCommentsMutations';
import { GET_ISSUE_COMMENTS } from './IssueCommentsQueries';

interface CommentListType {
  issueID: string;
}

export const CommentList = ({ issueID }: CommentListType) => {
  const styles = useStyles2(getStyles);

  const [oldestFirst, setOldestFirst] = useState<boolean>(true);
  const [selectedComment, setSelectedComment] = useState<string>('');

  const { data } = useSuspenseQuery<GetIssueCommentsQuery>(GET_ISSUE_COMMENTS, {
    fetchPolicy: 'cache-and-network',
    variables: {
      id: issueID,
    },
  });

  const [removeComment] = useMutation<RemoveCommentMutation>(REMOVE_COMMENT, {
    refetchQueries: [GET_ISSUE_COMMENTS],
    ignoreResults: true,
    onError: () => {
      getAppEvents().publish({
        type: AppEvents.alertError.name,
        payload: [`Error: failed to delete comment`],
      });
    },
  });

  const deleteComment = (commentID: string) => {
    removeComment({ variables: { input: { issueId: issueID, commentId: commentID } } });
    setSelectedComment('');
  };

  const comments = data?.issue?.comments || [];
  const sortedComments = oldestFirst ? comments : [...comments].reverse();

  return (
    <>
      <Divider />
      <Stack justifyContent="space-between" alignItems="center">
        <Text variant="h3">Comments</Text>
        <Select
          options={[
            { label: 'Oldest to Newest', value: true },
            { label: 'Newest to Oldest', value: false },
          ]}
          value={oldestFirst}
          onChange={(option) => setOldestFirst(option.value!)}
          defaultValue={{ label: 'Oldest to Newest', value: true }}
          width={20}
        />
      </Stack>
      {sortedComments.length ? (
        <ul className={styles.comments}>
          {sortedComments.map((comment, index) => (
            <li key={index}>
              <CommentCard
                userName={comment.userName}
                dateString={comment.date}
                comment={comment.comment}
                onDelete={() => setSelectedComment(comment.id)}
                gravatarUrl={comment.gravatarUrl}
              />
            </li>
          ))}
        </ul>
      ) : (
        <Box display="flex" justifyContent="center" marginY={1.5}>
          <Text italic color="secondary">
            No comments.
          </Text>
        </Box>
      )}
      <ConfirmModal
        isOpen={Boolean(selectedComment)}
        title="Delete Comment?"
        body="Are you sure you'd like to delete this comment?"
        confirmText="Delete"
        onConfirm={() => deleteComment(selectedComment)}
        onDismiss={() => setSelectedComment('')}
      />
    </>
  );
};

const getStyles = (theme: GrafanaTheme2) => ({
  comments: css({
    marginTop: theme.spacing(2),
    listStyleType: 'none',
  }),
});
