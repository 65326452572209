export const REPOSITORIES = {
  GITHUB: 'github',
  DOCKER_HUB: 'hub.docker.com',
  GCR_US: 'us.gcr.io',
  GAR_US: 'us-docker.pkg.dev',
  DOCKER_IO: 'docker.io',
  QUAY_IO: 'quay.io',
  K8S_REGISTRY: 'registry.k8s.io',
  UPBOUND_XPKG: 'xpkg.upbound.io',
  REDPANDA_DOCKER: 'docker.redpanda.com',
  AWS_ECR_OLD: 'public.ecr.aws',
  AWS_ECR: 'aws.ecr',
  GCR_GLOBAL: 'gcr.io',
  GHCR_IO: 'ghcr.io',
};
