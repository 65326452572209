import { reportInteraction } from '@grafana/runtime';
import pluginJson from '../plugin.json';

export enum UserInteraction {
  CollectablePanelAdded = 'collectable_panel_added',
}

const createInteractionName = (type: UserInteraction) => {
  return `${pluginJson.id.replace(/-/g, '_')}_${type}`;
};

export function reportAppInteraction(type: UserInteraction, properties: Record<string, string> = {}) {
  reportInteraction(createInteractionName(type), properties);
}
