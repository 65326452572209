import { Alert, Box, ErrorBoundary, LoadingPlaceholder } from '@grafana/ui';
import { Suspense } from 'react';

import { AddCommentForm } from './AddCommentForm';
import { CommentList } from './CommentList';

interface IssueCommentsType {
  issueID: string;
}

export const IssueComments = ({ issueID }: IssueCommentsType) => {
  return (
    <ErrorBoundary>
      {({ error }) => {
        if (error) {
          return (
            <Alert
              title="Error: failed to load comments"
              onRemove={() => window.location.reload()}
              buttonContent="Reload"
            >
              {error.message}
            </Alert>
          );
        }
        return (
          <Box marginTop={2}>
            <Suspense fallback={<LoadingPlaceholder text="Loading comments..." />}>
              <CommentList issueID={issueID} />
            </Suspense>
            <AddCommentForm issueID={issueID} />
          </Box>
        );
      }}
    </ErrorBoundary>
  );
};
