import { Box, Icon, IconName, Stack, Text, useTheme2 } from '@grafana/ui';

interface TitleWithIconType {
  title: string;
  icon: IconName;
  color?: string;
}

export const TitleWithIcon = ({ title, icon, color }: TitleWithIconType) => {
  const theme = useTheme2();

  let iconColor = color || theme.colors.primary.text;

  switch (color) {
    case 'error':
      iconColor = theme.colors.error.main;
      break;
    case 'success':
      iconColor = theme.colors.success.main;
      break;
    case 'warning':
      iconColor = theme.colors.warning.main;
      break;
  }

  return (
    <Stack direction="row" alignItems="center" gap={0}>
      <Box marginRight={1.5}>
        <Icon name={icon} width="32" height="32" color={iconColor} />
      </Box>
      <Text variant="h1">{title}</Text>
    </Stack>
  );
};
