import {
  CustomVariable,
  EmbeddedScene,
  SceneAppPage,
  SceneControlsSpacer,
  SceneFlexLayout,
  SceneRefreshPicker,
  SceneVariableSet,
  VariableValueSelectors,
} from '@grafana/scenes';
import { PLUGIN_ROOT } from 'shared/constants';
import { VariableHide } from '@grafana/schema';
import { LogSourceLayout } from './LogSourceLayout';
import { GenericButton } from '../generic.button';
import { EventButton } from '../event.button';
import { DetectEventTypes } from '../events';
import { LogSourceExportModal } from './LogSourceExportModal';
import { logsourceQueryRunner } from './queryRunners/directory';
import { DetectScenesVariables } from '../variables';

const getScene = (): EmbeddedScene => {
  const layout = new LogSourceLayout({});
  const modal = new LogSourceExportModal({});

  const logsourceVariable = new CustomVariable({
    name: DetectScenesVariables.LogSource,
    hide: VariableHide.hideVariable,
    isMulti: true,
  });

  const editButton = new GenericButton({
    locationTemplate: PLUGIN_ROOT + '/scenes/logsource/editor?var-logsource=${logsource}',
    currentTab: true,
    text: 'Edit',
    variant: 'secondary',
    disabled:
      Array.isArray(logsourceVariable.getValue()) && (logsourceVariable.getValue() as Array<string>).length !== 1,
  });

  const exportButton = new EventButton({
    eventName: DetectEventTypes.LogSourceExport,
    text: 'Export',
    variant: 'secondary',
    disabled:
      Array.isArray(logsourceVariable.getValue()) && (logsourceVariable.getValue() as Array<string>).length !== 1,
  });

  const scene = new EmbeddedScene({
    key: 'logsource-overview',
    body: new SceneFlexLayout({
      children: [modal, layout],
    }),
    controls: [
      new VariableValueSelectors({}),
      new SceneControlsSpacer(),
      new SceneRefreshPicker({}),
      editButton,
      exportButton,
    ],
    $variables: new SceneVariableSet({
      variables: [logsourceVariable],
    }),
  });

  scene.addActivationHandler(() => {
    let unsub = logsourceVariable.subscribeToState((variable) => {
      editButton.setState({
        disabled: (variable.value as Array<string>).length !== 1,
      });
      exportButton.setState({
        disabled: (variable.value as Array<string>).length !== 1,
      });

      modal.setState({
        $data: logsourceQueryRunner(variable.value as string),
      });
    });

    const value = logsourceVariable.getValue() as Array<string>;
    if (value.length === 1) {
      modal.setState({
        $data: logsourceQueryRunner(value[0]),
      });
    }

    return () => {
      unsub.unsubscribe();
      logsourceVariable.changeValueTo([]);
    };
  });

  return scene;
};

export const getLogsourceOverviewScene = () =>
  new SceneAppPage({
    title: 'Log Source Configs',
    url: `${PLUGIN_ROOT}/scenes/logsource/overview`,
    preserveUrlKeys: ['var-logsource'],
    titleIcon: 'eye',
    getScene,
  });
