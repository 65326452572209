import { css } from '@emotion/css';
import { GrafanaTheme2 } from '@grafana/data';
import { useStyles2, CustomCellRendererProps, TextLink, Text } from '@grafana/ui';
import { memo } from 'react';
import { SOURCES_ROUTE } from 'shared/constants/routes/appRoutes';

export interface VersionLinkCellType extends CustomCellRendererProps {
  value: string;
  cve: string;
}

const VersionLinkCell = memo((props: VersionLinkCellType) => {
  const styles = useStyles2(getStyles);
  const {
    value,
    frame: { fields },
    rowIndex,
    cve,
  } = props;

  const isArchived = fields.find((field) => field.name === 'isArchived')?.values[rowIndex];
  const sourceID = fields.find((field) => field.name === 'sourceID')?.values[rowIndex];
  const versionID = fields.find((field) => field.name === 'versionID')?.values[rowIndex];

  return (
    <div className={styles.wrapper}>
      {isArchived ? (
        <Text color="primary">
          {value} <Text color="secondary">(archived)</Text>
        </Text>
      ) : (
        <TextLink
          href={`${SOURCES_ROUTE}/${sourceID}/version/${versionID}?var-cve=${cve}&var-hideDismissed=false`}
          weight="bold"
          inline={false}
        >
          {value}
        </TextLink>
      )}
    </div>
  );
});

const getStyles = (theme: GrafanaTheme2) => ({
  wrapper: css({
    color: theme.colors.primary.text,
  }),
});

VersionLinkCell.displayName = 'VersionLinkCell';

export { VersionLinkCell };
