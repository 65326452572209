import {
  EmbeddedScene,
  SceneAppPage,
  SceneAppPageLike,
  SceneFlexItem,
  SceneFlexLayout,
  SceneReactObject,
  SplitLayout,
} from '@grafana/scenes';
import { LinkButton } from '@grafana/ui';
import { CveExceptionAlert } from 'components/CveExceptionAlert';
import { CveHeader } from 'components/CveHeader';
import { CveDescriptionContainer, CveSummaryTableContainer } from 'components/CveReport';
import { CVE_DETAILS_ROUTE, CVE_EXCEPTIONS_CREATE_ROUTE } from 'shared/constants/routes/appRoutes';

import { getImpactedVersionsTable } from './panels';
import { getImpactedVersionsQueryRunner } from './queryRunners';

interface GetCvePageType {
  apiUrl: string;
  cve: string;
  parent: SceneAppPageLike;
}

export const getCveDrilldown = ({ apiUrl, cve, parent }: GetCvePageType) =>
  new SceneAppPage({
    title: 'CVE',
    renderTitle: () => CveHeader({ cve }),
    key: 'cveDrilldown',
    url: `${CVE_DETAILS_ROUTE}/${cve}`,
    controls: [
      new SceneReactObject({
        component: () => (
          <LinkButton variant="destructive" size="sm" href={CVE_EXCEPTIONS_CREATE_ROUTE}>
            Create Exception on {cve}
          </LinkButton>
        ),
      }),
    ],
    getParentPage: () => parent,
    getScene: () => {
      return new EmbeddedScene({
        key: 'cve',
        body: new SceneFlexLayout({
          direction: 'column',
          children: [
            new SceneFlexItem({
              height: 'min-content',
              body: new SceneReactObject({
                component: () => CveExceptionAlert({ cve }),
              }),
            }),
            new SceneFlexLayout({
              direction: 'row',
              children: [
                new SplitLayout({
                  initialSize: 0.5,
                  direction: 'row',
                  height: '100%',
                  primary: new SceneFlexItem({
                    $data: getImpactedVersionsQueryRunner(apiUrl, cve),
                    body: getImpactedVersionsTable(cve),
                  }),
                  secondary: new SceneFlexLayout({
                    direction: 'column',
                    children: [
                      new SceneFlexItem({
                        height: 'min-content',
                        body: new SceneReactObject({
                          component: () => CveSummaryTableContainer({ cve }),
                        }),
                      }),
                      new SceneFlexItem({
                        body: new SceneReactObject({
                          component: () => CveDescriptionContainer({ cve }),
                        }),
                      }),
                    ],
                  }),
                }),
              ],
            }),
          ],
        }),
      });
    },
  });
