import {
  SceneObjectState,
  SceneObjectBase,
  SceneComponentProps,
  sceneGraph,
  VariableDependencyConfig,
} from '@grafana/scenes';
import { SourceOverviewTableContainer } from 'components/SourceOverviewTable';

interface SourceOverviewTableState extends SceneObjectState {
  group: string;
  latestOnly: string;
  onlyIssuesWithSlo: string;
  excludeK8sSources: string;
}

export class SourceOverviewTable extends SceneObjectBase<SourceOverviewTableState> {
  static Component = SourceOverviewTableRenderer;

  protected _variableDependency = new VariableDependencyConfig(this, {
    statePaths: ['group', 'latestOnly', 'onlyIssuesWithSlo', 'excludeK8sSources'],
  });
}

function SourceOverviewTableRenderer({ model }: SceneComponentProps<SourceOverviewTable>) {
  const { group, latestOnly, onlyIssuesWithSlo, excludeK8sSources } = model.useState();

  return (
    <SourceOverviewTableContainer
      group={sceneGraph.interpolate(model, group)}
      latestOnly={sceneGraph.interpolate(model, latestOnly)}
      onlyIssuesWithSlo={sceneGraph.interpolate(model, onlyIssuesWithSlo)}
      excludeK8sSources={sceneGraph.interpolate(model, excludeK8sSources)}
    />
  );
}
