import { SceneAppPage, SceneReactObject } from '@grafana/scenes';
import { LinkButton } from '@grafana/ui';
import { CONFIGURE_SOURCE_ROUTE, PROJECTS_ROUTE } from 'shared/constants/routes/appRoutes';
import { GRAFANA_LOGO_PNG } from 'shared/constants/routes/imageRoutes';

import { getOverviewTab } from './getOverviewTab';
import { getPlanReleaseTab } from './getPlanReleaseTab';
import { getSourcesTab } from './getSourcesTab';

interface GetProjectsPageType {
  apiUrl: string;
}

export const getProjectsPage = ({ apiUrl }: GetProjectsPageType) =>
  new SceneAppPage({
    title: 'Projects',
    titleImg: GRAFANA_LOGO_PNG,
    key: 'projects',
    url: PROJECTS_ROUTE,
    controls: [
      new SceneReactObject({
        component: () => (
          <LinkButton icon="plus" variant="success" size="sm" href={CONFIGURE_SOURCE_ROUTE}>
            Configure a New Source
          </LinkButton>
        ),
      }),
    ],
    tabs: [getOverviewTab({ apiUrl }), getSourcesTab({ apiUrl }), getPlanReleaseTab()],
  });
