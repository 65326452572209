import { css } from '@emotion/css';
import { GrafanaTheme2 } from '@grafana/data';
import { Box, useStyles2 } from '@grafana/ui';

import { hexToRgba } from '../utils';

import { CountBox } from './CountBox';
import type { CveCounts } from './VersionSummaryCard';

interface SeverityCountsType {
  counts: CveCounts;
  isSlo?: boolean;
  isStale?: boolean;
}

export const SeverityCounts = ({ counts, isSlo, isStale }: SeverityCountsType) => {
  const styles = useStyles2(getStyles);

  const getStyleClass = (baseStyle: string) => {
    if (isStale) {
      return styles.stale;
    }
    if (isSlo) {
      return styles.critical;
    }
    return baseStyle;
  };

  return (
    <Box display="flex" justifyContent="flex-start">
      <CountBox label="Critical" count={counts.critical} styleClass={getStyleClass(styles.critical)} />
      <CountBox label="High" count={counts.high} styleClass={getStyleClass(styles.high)} />
      <CountBox label="Medium" count={counts.medium} styleClass={getStyleClass(styles.medium)} />
      <CountBox label="Low" count={counts.low} styleClass={getStyleClass(styles.low)} />
    </Box>
  );
};

const getStyles = (theme: GrafanaTheme2) => ({
  critical: css({
    border: `1px solid ${theme.visualization.getColorByName('dark-red')}`,
    background: hexToRgba(theme.visualization.getColorByName('dark-red'), 0.08),
  }),
  high: css({
    border: `1px solid ${theme.visualization.getColorByName('dark-orange')}`,
    background: hexToRgba(theme.visualization.getColorByName('dark-orange'), 0.08),
  }),
  medium: css({
    border: `1px solid ${theme.visualization.getColorByName('dark-yellow')}`,
    background: hexToRgba(theme.visualization.getColorByName('dark-yellow'), 0.08),
  }),
  low: css({
    border: `1px solid ${theme.visualization.getColorByName('grey')}`,
    background: hexToRgba(theme.visualization.getColorByName('grey'), 0.08),
  }),
  stale: css({
    border: `1px solid ${theme.colors.secondary.border}`,
    background: hexToRgba(theme.colors.secondary.border, 0.1),
    color: theme.colors.text.secondary,
  }),
});
