import { SceneAppPage, SceneAppPageLike, SceneReactObject } from '@grafana/scenes';
import { TriggerScanButton } from 'components/TriggerScan';
import { VersionHeader } from 'components/VersionHeader';
import { SOURCES_ROUTE } from 'shared/constants/routes/appRoutes';

import { getLicensesTab } from './getLicenseDrilldown';
import { getSecretsTab } from './getSecretsTab';
import { getVulnerabilitiesTab } from './getVulnerabilitiesTab';

interface GetVersionDrilldownType {
  apiUrl: string;
  sourceID: string;
  versionID: string;
  parent: SceneAppPageLike;
}

export const getVersionDrilldown = ({ apiUrl, sourceID, versionID, parent }: GetVersionDrilldownType) =>
  new SceneAppPage({
    title: 'Version',
    renderTitle: () => VersionHeader({ versionID }),
    key: 'versionDrilldown',
    url: `${SOURCES_ROUTE}/${sourceID}/version/${versionID}`,
    controls: [
      new SceneReactObject({
        component: () => TriggerScanButton({ versionID }),
      }),
    ],
    getParentPage: () => parent,
    tabs: [
      getVulnerabilitiesTab({ apiUrl, sourceID, versionID }),
      getSecretsTab({ apiUrl, sourceID, versionID }),
      getLicensesTab({ sourceID, versionID }),
    ],
  });
