import { AppEvents } from '@grafana/data';
import { getAppEvents } from '@grafana/runtime';
import { getLicense } from 'api/getLicense';
import { useEffect, useState } from 'react';
import { ErrorResponse } from 'shared/types';

export const useFetchLicense = (name: string) => {
  const [licenseDoc, setLicenseDoc] = useState<string | ErrorResponse>();
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (!name) {
      return;
    }
    setLoading(true);
    getLicense(name)
      .then(({ data }) => setLicenseDoc(data))
      .catch(() =>
        getAppEvents().publish({
          type: AppEvents.alertError.name,
          payload: [`Error: failed to fetch license from spdx`],
        })
      )
      .finally(() => setLoading(false));
  }, [name]);

  return {
    licenseDoc,
    loading,
  };
};
