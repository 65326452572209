import { TypedDocumentNode, gql } from '@apollo/client';
import { GetSourceSummariesWithSloQuery, GetSourceSummariesWithSloQueryVariables } from '__generated__/graphql';

export const GET_SOURCES_SUMMARIES_WITH_SLO: TypedDocumentNode<
  GetSourceSummariesWithSloQuery,
  GetSourceSummariesWithSloQueryVariables
> = gql`
  query getSourceSummariesWithSlo($filters: SummaryFilters) {
    summary(filters: $filters) {
      sourcesWithSlo {
        source {
          id
          name
          type
          origin
          groups {
            name
          }
        }
        totalCveCounts {
          critical
          high
          medium
          low
        }
        lowestSloRemaining
        totalSecrets
      }
    }
  }
`;
